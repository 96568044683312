import {
  AppBar,
  Badge,
  Divider,
  FormControl,
  Grid,
  Hidden,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";
import { useStore } from "../../app/stores/store";
import {
  PaymentType,
  Sale,
  SalesDetail,
  SalesDetailValues,
  SalesFormValues,
  SalesPayment,
  SalesPaymentValues,
} from "../../app/models/sale";
import { Helmet } from "react-helmet";
import POSTotal from "./POSTotal";
import * as Yup from "yup";
import { v4 as uuid } from "uuid";
import POSPayment from "./POSPayment";
import { useHistory, useParams } from "react-router-dom";
import { usePosStyles } from "../../app/layout/pos";
import { useReactToPrint } from "react-to-print";
import POSSlip from "./POSSlip";
import POSButton from "./POSButton";
import { ItemDropdown } from "../../app/models/item";
import SearchIcon from "@material-ui/icons/Search";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PaymentIcon from "@material-ui/icons/Payment";
import TabPanel from "../../app/layout/TabPanel";
import { Customer } from "../../app/models/customer";
import POSItemsGeneral from "./POSGeneralItems";
import POSCartGeneral from "./POSGeneralCart";
import POSGeneralTransaction from "./POSGeneralTransaction";
import { MedicalPersonnel } from "../../app/models/medicalPersonnel";
import { Nurse } from "../../app/models/nurses";

export default observer(function POSGeneral() {
  const classes = usePosStyles();
  const {
    salesStore,
    itemStore,
    medicalPersonnelStore,
    nurseStore,
    snackbarStore,
    storeStore,
  } = useStore();
  const { loadStoresGranted, loadingStore } = storeStore;
  const { loadItemOnlyForMedicalDoctor, loadingItem } = itemStore;
  const {
    loadPaymentType,
    paymentTypes,
    loadBankAccount,
    bankAccounts,
    createSales,
    loadDocument,
    loadingSales,
    updateSales,
  } = salesStore;
  const { persons, loadDropdownPerson, loadingPerson } = medicalPersonnelStore;
  const {
    persons: nurses,
    loadDropdownPerson: loadDropdownNurse,
    loadingPerson: loadingNurse,
  } = nurseStore;
  const { openSnackbar } = snackbarStore;
  const [sales, setSales] = React.useState<SalesFormValues>(
    new SalesFormValues()
  );
  const [slip, setSlip] = React.useState<Sale>();
  const [details, setDetails] = React.useState<SalesDetail[]>([]);
  const [open, setOpen] = React.useState(false);
  const [payments, setPayments] = React.useState<SalesPayment[]>([]);
  const [items, setItems] = React.useState<ItemDropdown[] | undefined>(
    undefined
  );
  const [customer, setCustomer] = React.useState<Customer | null>(null);
  const [person, setPerson] = React.useState<MedicalPersonnel | null>(null);
  const [nurse, setNurse] = React.useState<Nurse | null>(null);
  const [status, setStatus] = React.useState<"save" | "pay" | undefined>(
    undefined
  );
  const [paymentGroup, setPaymentGroup] = React.useState<PaymentType[]>([]);
  const [value, setValue] = React.useState(0);
  const [search, setSearch] = React.useState("");
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const componentRef = useRef(null);

  useEffect(() => {
    loadItemOnlyForMedicalDoctor().then((items) => {
      setItems(items);
    });
    if (paymentTypes.length === 0) loadPaymentType();
    if (bankAccounts.length === 0) loadBankAccount();
    if (persons.length === 0) loadDropdownPerson();
    if (nurses.length === 0) loadDropdownNurse();
    loadStoresGranted().then((storeId) => {
      let newSales = new SalesFormValues(undefined, undefined, storeId);
      setSales(newSales);
      if (id) {
        loadDocument(id).then((result) => {
          loadDocumentResult(result);
        });
      }
    });
  }, [paymentTypes.length, bankAccounts.length, persons.length, nurses.length, loadDropdownPerson, loadStoresGranted, loadPaymentType, loadBankAccount, loadItemOnlyForMedicalDoctor, id, loadDocument, loadDropdownNurse]);

  const loadDocumentResult = (result: Sale | undefined) => {
    if (result) {
      let sales = {
        ...result,
        payments: result.payments.filter((x) => x.paymentType.display),
        details: result.details.map(
          (x) =>
            new SalesDetail(x.id, x.qty, undefined, x.salesId, {
              id: x.itemId,
              fullCode: x.fullCode,
              itemCategory: x.itemCategory,
              itemType: x.itemType,
              itemDescription: x.itemDescription,
              itemCode: x.itemCode,
              itemPrice: x.itemPrice,
              itemDisc: x.itemDisc,
            } as ItemDropdown)
        ),
      };
      let newSales = new SalesFormValues(undefined, sales, sales.store.id);
      setSales(newSales);
      setCustomer(sales.customer);
      setDetails(sales.details);
      setPayments(sales.payments);
      setPerson(sales.personnel);
      setNurse(sales.nurse);
      setPaymentGroup(
        sales.payments.map((x) => {
          return x.paymentType as PaymentType;
        })
      );
    }
  };

  const schema = Yup.object().shape(
    {
      date: Yup.date().required("Date is required."),
      storeId: Yup.string().required("Store is required."),
      customerId: Yup.string().required("Customer is required."),
      personnelId: Yup.string()
        .ensure()
        .when("nurseId", {
          is: "",
          then: Yup.string().required("Medical Personnel is required."),
        }),
      nurseId: Yup.string()
        .ensure()
        .when("personnelId", {
          is: "",
          then: Yup.string().required("Nurse is required."),
        }),
      disc: Yup.number()
        .typeError("Disc is must be a number.")
        .required("Disc is required."),
      details: Yup.array().of(
        Yup.object().shape({
          qty: Yup.number()
            .typeError("Quantity is must be a number.")
            .positive("Quantity is must be greater than zero.")
            .required("Quantity is required."),
        })
      ),
      payments: Yup.array().of(
        Yup.object().shape({
          date: Yup.date().required("Date is required."),
          bankAccountId: Yup.string().when("needAccountNo", {
            is: true,
            then: Yup.string().required("Bank account is required."),
            otherwise: Yup.string().notRequired(),
          }),
          amount: Yup.number()
            .typeError("Quantity is must be a number.")
            .positive("Quantity is must be greater than zero.")
            .required("Quantity is required."),
        })
      ),
    },
    [["nurseId", "personnelId"]]
  );

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleFormSave = (submitForm: () => void) => {
    setStatus("save");
    submitForm();
  };

  const formClear = () => {
    let newSales = new SalesFormValues(undefined, undefined, sales.storeId);
    setSales(newSales);
    setDetails([]);
    setPayments([]);
    setPaymentGroup([]);
    setStatus(undefined);
    setValue(0);
    setSearch("");
    setCustomer(null);
  };

  const handleFormClear = () => {
    formClear();
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleFormSubmit = (
    sales: SalesFormValues,
    resetForm: () => void,
    setSubmitting: (isSubmitting: boolean) => void,
    status?: "save" | "pay"
  ) => {
    if (status) {
      if (!id) {
        const newId = uuid();
        let newSales = new SalesFormValues({
          ...sales,
          id: newId,
          details: sales.details.map(
            (x) => new SalesDetailValues({ ...x, salesId: newId })
          ),
          payments: sales.payments.map(
            (x) => new SalesPaymentValues({ ...x, salesId: newId })
          ),
          status: 2,
        } as SalesFormValues);
        createSales(newSales)
          .catch((error) => {
            openSnackbar(error, "error");
            setSubmitting(false);
            setOpen(false);
            setStatus(undefined);
          })
          .then((result) => {
            if (result !== undefined) {
              openSnackbar(result.message, "success");
              formClear();
              setSlip(result.sales);
              setOpen(false);
              setStatus(undefined);
              handlePrint();
              resetForm();
            }
          });
      } else {
        updateSales(sales)
          .catch((error) => {
            openSnackbar(error, "error");
            setSubmitting(false);
            setOpen(false);
            setStatus(undefined);
          })
          .then((result) => {
            if (result !== undefined) {
              openSnackbar(result.message, "success");
              history.push("/invoice");
            }
          });
      }
    } else {
      if (!id) {
        setPayments(payments.filter((x) => x.amount > 0));
        setSales({
          ...sales,
          payments: sales.payments.filter(
            (x) => x.amount !== undefined && x.amount > 0
          ),
        });
        setPaymentGroup(
          payments
            .filter((x) => x.amount > 0)
            .map((x) => {
              return x.paymentType;
            })
        );
      }
      setOpen(true);
      setSubmitting(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>PERSADA MEDIC - POINT OF SALES</title>
      </Helmet>
      <Formik
        validationSchema={schema}
        enableReinitialize
        initialValues={sales}
        onSubmit={(values, { resetForm, setSubmitting, validateField }) =>
          handleFormSubmit(values, resetForm, setSubmitting, status)
        }
      >
        {({
          handleSubmit,
          isSubmitting,
          values,
          submitForm,
          errors,
          setFieldValue,
          setFieldTouched,
          touched,
        }) => (
          <Form onSubmit={handleSubmit} autoComplete="off">
            <Hidden mdDown implementation="css">
              <Grid container spacing={1}>
                <Grid item xl={4} lg={4} md={12} xs={12}>
                  <Paper className={classes.title}>
                    <Typography align="center" variant="h5">
                      POINT OF SALES
                    </Typography>
                  </Paper>
                  <Paper className={classes.items}>
                    <POSItemsGeneral
                      sales={values}
                      setSales={setSales}
                      details={details}
                      setDetails={setDetails}
                      items={items}
                      setItems={setItems}
                      search={search}
                      setSearch={setSearch}
                      isSubmitting={isSubmitting}
                      loading={loadingItem}
                    />
                  </Paper>
                </Grid>
                <Grid item xl={5} lg={4} md={12} xs={12}>
                  <Paper className={classes.cart}>
                    <Typography align="center" variant="h5">
                      CART
                    </Typography>
                    <Divider />
                    <POSCartGeneral
                      sales={values}
                      setSales={setSales}
                      isSubmitting={isSubmitting}
                      details={details}
                      setDetails={setDetails}
                      loading={
                        loadingItem ||
                        loadingStore ||
                        loadingSales ||
                        loadingPerson ||
                        loadingNurse
                      }
                    />
                  </Paper>
                </Grid>
                <Grid item xl={3} lg={4} md={12} xs={12}>
                  <Paper className={classes.total}>
                    <Typography align="center" variant="h5">
                      TOTAL
                    </Typography>
                    <Divider />
                    <POSTotal sale={values} details={details} />
                  </Paper>
                  <POSGeneralTransaction
                    sales={values}
                    setFieldValue={setFieldValue}
                    setSales={setSales}
                    customer={customer}
                    setCustomer={setCustomer}
                    person={person}
                    setPerson={setPerson}
                    nurse={nurse}
                    setNurse={setNurse}
                    isSubmitting={isSubmitting}
                    errors={errors}
                    touched={touched}
                    setFieldTouched={setFieldTouched}
                  />
                  <FormControl fullWidth>
                    <POSButton
                      id={id}
                      loading={
                        isSubmitting || details.length === 0 || loadingSales
                      }
                      handleFormSave={handleFormSave}
                      submitForm={submitForm}
                      handleFormClear={handleFormClear}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Hidden>
            <Hidden lgUp implementation="css">
              <Grid container spacing={1}>
                <Grid item md={12} xs={12}>
                  <Paper className={classes.title}>
                    <Typography align="center" variant="h5">
                      POINT OF SALES
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
              <AppBar
                position="fixed"
                color="default"
                className={classes.appBar}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                >
                  <Tab icon={<SearchIcon />} label="Search" />
                  <Tab
                    icon={
                      <Badge
                        badgeContent={details.length}
                        overlap="circular"
                        color="secondary"
                      >
                        <ShoppingCartIcon />
                      </Badge>
                    }
                    label="Cart"
                  />
                  <Tab icon={<PaymentIcon />} label="Payment" />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                <Paper className={classes.items}>
                  <POSItemsGeneral
                    sales={values}
                    setSales={setSales}
                    details={details}
                    setDetails={setDetails}
                    items={items}
                    setItems={setItems}
                    search={search}
                    setSearch={setSearch}
                    isSubmitting={isSubmitting}
                    loading={loadingItem}
                  />
                </Paper>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Paper className={classes.cart}>
                  <Typography align="center" variant="h5">
                    CART
                  </Typography>
                  <Divider />
                  <POSCartGeneral
                    sales={values}
                    setSales={setSales}
                    isSubmitting={isSubmitting}
                    details={details}
                    setDetails={setDetails}
                    loading={loadingItem || loadingStore || loadingSales}
                  />
                </Paper>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Paper className={classes.total}>
                  <Typography align="center" variant="h5">
                    TOTAL
                  </Typography>
                  <Divider />
                  <POSTotal sale={values} details={details} />
                </Paper>
                <POSGeneralTransaction
                  sales={values}
                  setFieldValue={setFieldValue}
                  setSales={setSales}
                  customer={customer}
                  setCustomer={setCustomer}
                  person={person}
                  setPerson={setPerson}
                  nurse={nurse}
                  setNurse={setNurse}
                  isSubmitting={isSubmitting}
                  errors={errors}
                  touched={touched}
                  setFieldTouched={setFieldTouched}
                />
                <FormControl fullWidth className={classes.grid}>
                  <POSButton
                    id={id}
                    loading={
                      isSubmitting || details.length === 0 || loadingSales
                    }
                    handleFormSave={handleFormSave}
                    submitForm={submitForm}
                    handleFormClear={handleFormClear}
                  />
                </FormControl>
              </TabPanel>
            </Hidden>
            <POSPayment
              sales={values}
              details={details}
              setSales={setSales}
              open={open}
              setOpen={setOpen}
              payments={payments}
              setPayments={setPayments}
              paymentGroup={paymentGroup}
              setPaymentGroup={setPaymentGroup}
              isSubmitting={isSubmitting}
              submitForm={submitForm}
              setStatus={setStatus}
            />
          </Form>
        )}
      </Formik>
      <div style={{ display: "none" }}>
        <POSSlip componentToPrint={componentRef} sales={slip} />
      </div>
    </>
  );
});
