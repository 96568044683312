import { PersonnelFee } from "./medicalPersonnel";

export interface Nurse {
  id: string;
  name: string;
  fee: number;
  isActive: boolean;
  details: PersonnelFee[];
}

export class NurseSearch {
  startDate: Date = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
  );
  endDate: Date = new Date();

  constructor(search?: NurseSearch) {
    if (search) {
      this.startDate = search.startDate;
      this.endDate = search.endDate;
    }
  }
}

export class NurseFormValues {
  id?: string = "";
  name: string = "";
  fee: number = Number.NaN;
  isActive: boolean = true;

  constructor(person?: NurseFormValues) {
    if (person) {
      this.id = person.id;
      this.name = person.name;
      this.fee = person.fee;
      this.isActive = person.isActive;
    }
  }
}
