import { Divider, Grid, Typography } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React from "react";
import { SalesDetail, SalesFormValues } from "../../app/models/sale";
import { useStore } from "../../app/stores/store";

interface Props {
  sale: SalesFormValues;
  details: SalesDetail[];
}

export default observer(function POSTotal({ sale, details }: Props) {
  const { salesStore } = useStore();
  const { getSubTotal, getDiscSubTotal, getTotalQty } = salesStore;
  const discSubTotal = getDiscSubTotal(sale.disc, details);
  const totalQty = getTotalQty(details);
  const subTotal = getSubTotal(details);
  const totalAmount = subTotal - sale.disc;

  return (
    <>
      <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
        <Grid item xs>
          <Typography variant="body1" component="p">
            Sub Total ({totalQty}):
          </Typography>
        </Grid>
        <Grid item>
          <Typography align="right" variant="body1" component="p">
            {subTotal.toLocaleString()}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
        <Grid item xs>
          <Typography variant="body2" component="p">
            Discount:
          </Typography>
        </Grid>
        <Grid item>
          <Typography align="right" variant="body2" component="p">
            {discSubTotal.toLocaleString("EN-US")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
        <Grid item xs>
          <Typography variant="body2" component="p">
            Tax:
          </Typography>
        </Grid>
        <Grid item>
          <Typography align="right" variant="body2" component="p">
            0
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
        <Grid item xs>
          <Typography variant="h5" component="p">
            Total Amount :
          </Typography>
        </Grid>
        <Grid item>
          <Typography align="right" variant="h5" component="p">
            {totalAmount.toLocaleString("EN-US")}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
});
