import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import * as Yup from "yup";
import { v4 as uuid } from "uuid";
import { Link, useHistory, useParams } from "react-router-dom";
import InputField from "../../app/common/form/InputField";
import MySwitch from "../../app/common/form/MySwitch";
import PhoneNoInputField from "../../app/common/form/PhoneNoInputField";
import { useStyles } from "../../app/layout/style";
import {
  Customer,
  CustomerFormValues,
  gender,
} from "../../app/models/customer";
import { useStore } from "../../app/stores/store";
import DateInputField from "../../app/common/form/DateInputFIeld";
import Dropdown from "../../app/common/form/Dropdown";

export default observer(function CustomerForm() {
  const classes = useStyles();
  const { customerStore, snackbarStore } = useStore();
  const { createCustomer, updateCustomer, loadCustomer, loadingCustomer } =
    customerStore;
  const { openSnackbar } = snackbarStore;
  const [customer, setCustomer] = React.useState<CustomerFormValues>(
    new CustomerFormValues()
  );
  const history = useHistory();
  let { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (id) {
      loadCustomer(id).then((customer) => {
        loadCustomerResult(customer);
      });
    }
  }, [id, loadCustomer]);

  const loadCustomerResult = (customer: Customer | undefined) => {
    if (customer) {
      const result = new CustomerFormValues({
        id: customer.id,
        customerName: customer.customerName,
        phoneNo: customer.phoneNo,
        dateOfBirth: customer.dateOfBirth,
        gender: customer.gender,
        address: customer.address,
        notes: customer.notes,
        isActive: customer.isActive,
      });
      setCustomer(result);
    }
  };

  const schema = Yup.object({
    customerName: Yup.string().required("Customer Name required."),
    phoneNo: Yup.string().required("Phone is required."),
  });

  const handleFormSubmit = (
    customer: CustomerFormValues,
    resetForm: () => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    if (!customer.id) {
      const newId = uuid();
      let newCustomer = {
        ...customer,
        id: newId,
      };
      createCustomer(newCustomer)
        .catch((error) => {
          openSnackbar(error, "error");
          setSubmitting(false);
        })
        .then((message) => {
          if (message !== undefined) {
            openSnackbar(message, "success");
            setCustomer(new CustomerFormValues());
            resetForm();
          }
        });
    } else {
      updateCustomer(customer)
        .catch((error) => {
          openSnackbar(error, "error");
          setSubmitting(false);
        })
        .then((message) => {
          if (message !== undefined) {
            openSnackbar(message, "success");
            history.push("/customers");
          }
        });
    }
  };

  return (
    <>
      <Paper className={classes.form}>
        <Typography variant="h5">Form {id ? "Update" : "Create"}</Typography>
        <Divider />
        <Formik
          validationSchema={schema}
          enableReinitialize
          initialValues={customer}
          onSubmit={(values, { resetForm, setSubmitting }) =>
            handleFormSubmit(values, resetForm, setSubmitting)
          }
        >
          {({ handleSubmit, isSubmitting, isValid, dirty }) => (
            <Form onSubmit={handleSubmit} autoComplete="off">
              <Grid container>
                <Grid item xs={12} lg={6}>
                  <InputField
                    variant="standard"
                    label="Customer Name"
                    name="customerName"
                    placeholder="Please input customer name"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={isSubmitting || loadingCustomer}
                  />
                  <PhoneNoInputField
                    variant="standard"
                    label="Phone"
                    name="phoneNo"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={isSubmitting || loadingCustomer}
                  />
                  <DateInputField
                    disableToolbar={false}
                    variant="dialog"
                    label="Date of Birth"
                    inputVariant="standard"
                    name="dateOfBirth"
                    disabled={isSubmitting || loadingCustomer}
                  />
                  <Dropdown
                    name="gender"
                    options={gender.map((x) => {
                      return { text: x, value: x };
                    })}
                    placeholder="Gender"
                    disabled={isSubmitting || loadingCustomer}
                  />
                  <InputField
                    variant="standard"
                    label="Address"
                    name="address"
                    multiline
                    minRows={4}
                    placeholder="Please input customer address"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={isSubmitting || loadingCustomer}
                  />
                  <InputField
                    variant="standard"
                    label="Notes"
                    name="notes"
                    multiline
                    minRows={4}
                    placeholder="Please input notes"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={isSubmitting || loadingCustomer}
                  />
                  {id && (
                    <MySwitch
                      name="isActive"
                      label="Active Customer"
                      disabled={isSubmitting || loadingCustomer}
                      checked
                    />
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="flex-end"
                alignItems="stretch"
                spacing={1}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting || !isValid || !dirty}
                  >
                    {isSubmitting && (
                      <CircularProgress
                        className={classes.progress}
                        size={16}
                        color="inherit"
                      />
                    )}
                    Save
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="default"
                    component={Link}
                    to="/customers"
                    disabled={isSubmitting || loadingCustomer}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </>
  );
});
