import { makeAutoObservable } from "mobx";
import { ServerError } from "../models/serverError";

interface Snackbar {
  open: boolean;
  hideDuration?: number;
  severity: Sevirity;
  message?: string | string[];
  messageServerError?: ServerError;
}

export type Sevirity = "error" | "info" | "success" | "warning";

export default class SnackbarStore {
  snackbar: Snackbar = {
    open: false,
    hideDuration: 6000,
    severity: "success",
    message: undefined,
    messageServerError: undefined,
  };

  constructor() {
    makeAutoObservable(this);
  }

  openSnackbar = (
    message: any | string,
    severity: Sevirity,
    hideDuration?: number
  ) => {
    this.snackbar.open = true;
    this.snackbar.severity = severity;
    this.snackbar.hideDuration = hideDuration;

    if (message && (typeof message === "string" || Array.isArray(message))) {
      this.snackbar.message = message;
      this.snackbar.messageServerError = undefined;
    } else {
      this.snackbar.message = undefined;
      this.snackbar.messageServerError = message as ServerError;
    }
  };

  closeSnackbar = () => {
    this.snackbar.open = false;
  };
}
