import {
  Chip,
  Grid,
  Paper,
  TablePagination,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useStyles } from "../../app/layout/style";
import { Sale } from "../../app/models/sale";
import { useStore } from "../../app/stores/store";
import ItemDeleteDialog from "../Items/ItemDeleteDialog";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import InvoiceCard from "./InvoiceCard";
import InvoiceCardSkeleton from "./InvoiceCardSkeleton";

export default observer(function InvoiceCardList() {
  const classes = useStyles();
  const theme = useTheme();
  const widthSizeMatch = useMediaQuery(theme.breakpoints.up("xl"));
  const { salesStore, snackbarStore, dialogStore } = useStore();
  const {
    documentList,
    deleteSales,
    loadingSales,
    loadingInvoiceList,
    rowsOptions,
    setRowsOptions,
    setPageSize,
    setPageNumber,
    sortByCard,
    setSortByCard,
    setOrderBy,
    orderBy,
    pagination,
    pageNumber,
    pageSize,
    headCells,
  } = salesStore;
  const { openSnackbar } = snackbarStore;
  const { openDialogDelete, closeDialog } = dialogStore;
  const [active, setActive] = React.useState<string | null>(null);
  const skeletonCardArray = Array(widthSizeMatch ? 8 : 6).fill("");

  useEffect(() => {
    if (documentList.length === 0 && headCells.length !== 0) {
      if (widthSizeMatch) {
        setRowsOptions([8, 8 * 2, 8 * 4, 8 * 8]);
        setPageSize(8);
      } else {
        setRowsOptions([6, 6 * 2, 6 * 4, 6 * 8]);
        setPageSize(6);
      }
    }
  }, [documentList.length, headCells.length, widthSizeMatch, setRowsOptions, setPageSize]);

  const handleSortByClick = (value: Extract<keyof Sale, string>) => {
    if (active === value) {
      setActive(null);
      setSortByCard(null);
    } else {
      setActive(value);
      setSortByCard(value);
    }
  };

  const handleOrderBy = () => {
    setOrderBy(orderBy === "asc" ? "desc" : "asc");
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNumber(1);
  };

  const handleDeleteSales = (id: string) => {
    openDialogDelete(<ItemDeleteDialog id={id} />, handleDelete);
  };

  const handleDelete = (id: string) => {
    deleteSales([id])
      .catch((error) => {
        closeDialog();
        openSnackbar(error, "error");
      })
      .then((message) => {
        if (message !== undefined) openSnackbar(message, "success");
        closeDialog();
      });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <Typography variant="body2" component="span">
              Sort by
            </Typography>
          </Grid>
          {sortByCard.map((value) => (
            <Grid item key={value.id}>
              <Chip
                label={value.label}
                clickable
                color={value.id === active ? "primary" : "default"}
                onClick={() => handleSortByClick(value.id)}
              />
            </Grid>
          ))}
          <Grid item>
            <Chip
              icon={<ImportExportIcon />}
              label={orderBy === "asc" ? "Ascending" : "Descending"}
              clickable
              variant="outlined"
              color={orderBy === "asc" ? "primary" : "secondary"}
              onClick={() => handleOrderBy()}
            />
          </Grid>
        </Grid>
      </Grid>
      {loadingSales || loadingInvoiceList
        ? skeletonCardArray.map((item, index) => (
            <Grid key={index} item md={6} sm={12} xs={12}>
              <InvoiceCardSkeleton />
            </Grid>
          ))
        : documentList.map((sales) => (
            <Grid key={sales.id} item md={6} sm={12} xs={12}>
              <InvoiceCard sales={sales} deleteSales={handleDeleteSales} />
            </Grid>
          ))}
      <Grid item xs={12}>
        <Paper className={classes.transferList}>
          <TablePagination
            rowsPerPageOptions={rowsOptions}
            component="div"
            count={pagination === null ? 0 : pagination.totalItems}
            page={pageNumber}
            onPageChange={handleChangePage}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
    </Grid>
  );
});
