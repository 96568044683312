import { Grid, Typography, Chip } from "@material-ui/core";
import { Formik, Form } from "formik";
import { observer } from "mobx-react-lite";
import React from "react";
import SearchInput from "../../app/common/form/SearchInput";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import { MedicalPersonnel } from "../../app/models/medicalPersonnel";
import { useStore } from "../../app/stores/store";
import MedicalPersonelCardList from "./MedicalPersonelCardList";

export default observer(function MedicalpersonnelList() {
  const { medicalPersonnelStore } = useStore();
  const { setPredicate, predicate, setSortBy, sortBy, orderBy, setOrderBy } =
    medicalPersonnelStore;
  const [active, setActive] = React.useState<string | null>(null);
  const [search, setSearch] = React.useState({
    search:
      predicate.get("search") === undefined ? "" : predicate.get("search"),
  });

  const handleSearchClick = (values: any) => {
    setPredicate(values.search);
    setSearch({ search: values.search });
  };

  const handleSortByClick = (
    value: Extract<keyof MedicalPersonnel, string>
  ) => {
    if (active === value) {
      setActive(null);
      setSortBy(null);
    } else {
      setActive(value);
      setSortBy(value);
    }
  };

  const handleOrderBy = () => {
    setOrderBy(orderBy === "asc" ? "desc" : "asc");
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={1}
    >
      <Grid item lg={6} md={6} xs={12}>
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <Typography variant="body2" component="span">
              Sort by
            </Typography>
          </Grid>
          {sortBy.map((value) => (
            <Grid item key={value.id}>
              <Chip
                label={value.label}
                clickable
                color={value.id === active ? "primary" : "default"}
                onClick={() => handleSortByClick(value.id)}
              />
            </Grid>
          ))}
          <Grid item>
            <Chip
              icon={<ImportExportIcon />}
              label={orderBy === "asc" ? "Ascending" : "Descending"}
              clickable
              variant="outlined"
              color={orderBy === "asc" ? "primary" : "secondary"}
              onClick={() => handleOrderBy()}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={4} md={6} xs={12}>
        <Formik
          enableReinitialize
          initialValues={search}
          onSubmit={(values) => handleSearchClick(values)}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit} autoComplete="off">
              <SearchInput placeholder="Search..." name="search" autoFocus />
            </Form>
          )}
        </Formik>
      </Grid>
      <MedicalPersonelCardList />
    </Grid>
  );
});
