import {
  Popper,
  Grow,
  Paper,
  Button,
  Grid,
  makeStyles,
  Theme,
  createStyles,
  Typography,
  Divider,
} from "@material-ui/core";
import React, { useEffect } from "react";
import FilterListIcon from "@material-ui/icons/FilterList";
import { useStyles } from "../../app/layout/style";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { Form, Formik } from "formik";
import { MedicalPersonnelSearch } from "../../app/models/medicalPersonnel";
import DateInputField from "../../app/common/form/DateInputFIeld";

const useLocalStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiTextField-root": {
        width: "40ch",
      },
    },
  })
);

export default observer(function MedicalPersonnelFeeFilter() {
  const classes = useStyles();
  const useLocalClasses = useLocalStyles();
  const { medicalPersonnelStore } = useStore();
  const {
    setPredicatePersonnelFee,
    loadDropdownPerson,
    persons,
    predicatePersonnelFee,
  } = medicalPersonnelStore;
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState<MedicalPersonnelSearch>(
    new MedicalPersonnelSearch()
  );
  const anchorRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (persons.length === 0) loadDropdownPerson();
  }, [persons.length, loadDropdownPerson]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    setSearch(
      new MedicalPersonnelSearch({
        startDate:
          predicatePersonnelFee.get("startDate") === undefined
            ? new Date()
            : new Date(predicatePersonnelFee.get("startDate")!),
        endDate:
          predicatePersonnelFee.get("endDate") === undefined
            ? new Date()
            : new Date(predicatePersonnelFee.get("endDate")!),
      })
    );
  };

  const handleSearch = (values: MedicalPersonnelSearch) => {
    setPredicatePersonnelFee(values);
    handleToggle();
  };

  return (
    <div ref={anchorRef} className={useLocalClasses.root}>
      <Button color="primary" size="small" onClick={handleToggle}>
        Filter <FilterListIcon />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        placement="bottom-end"
        style={{ zIndex: 3 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-end" ? "right top" : "right bottom",
            }}
          >
            <Paper className={classes.form}>
              <Typography variant="subtitle2">Filter</Typography>
              <Divider />
              <Formik
                enableReinitialize
                initialValues={search}
                onSubmit={(values) => handleSearch(values)}
              >
                {({ handleSubmit, values }) => (
                  <Form onSubmit={handleSubmit} autoComplete="off">
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      spacing={1}
                      className={useLocalClasses.root}
                    >
                      <Grid item>
                        <DateInputField
                          label="Start Date"
                          name="startDate"
                          maxDate={values.endDate}
                          placeholder="Please input start date"
                          inputVariant="outlined"
                          margin="dense"
                        />
                      </Grid>
                      <Grid item>
                        <DateInputField
                          label="End Date"
                          name="endDate"
                          minDate={values.startDate}
                          placeholder="Please input end date"
                          inputVariant="outlined"
                          margin="dense"
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          color="primary"
                          variant="contained"
                          type="submit"
                        >
                          Search
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
});
