import {
  Grid,
  Avatar,
  Typography,
  Tooltip,
  IconButton,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { QtyInputField } from "../../app/common/form/NumberInputField";
import { SalesDetail, SalesFormValues } from "../../app/models/sale";
import DeleteIcon from "@material-ui/icons/Delete";
import { ChangeEvent } from "react";
import { useStyles } from "../../app/layout/style";
import FaceIcon from "@material-ui/icons/Face";

interface Props {
  sales: SalesFormValues;
  setSales: (sales: SalesFormValues) => void;
  details: SalesDetail[];
  setDetails: (details: SalesDetail[]) => void;
  isSubmitting: boolean;
  loading: boolean;
}

export default observer(function POSCart({
  sales,
  setSales,
  details,
  setDetails,
  isSubmitting,
  loading,
}: Props) {
  const classes = useStyles();

  const handleQtyChange = (
    e: ChangeEvent<{
      value: unknown;
    }>,
    index: number
  ) => {
    const qty = Number(e.target.value);
    let items = [...details];
    let item = { ...details[index] };
    const amount = qty * (item.price - item.disc);
    if (qty) {
      item.totalAmount = amount;
      item.qty = qty;
    } else {
      item.totalAmount = 0;
      item.qty = 0;
    }
    items[index] = item;
    setDetails(items);
  };

  const handleDeleteItem = (detail: SalesDetail) => {
    setDetails(details.filter((x) => x.id !== detail.id));
    setSales(
      new SalesFormValues({
        ...sales,
        details: sales.details.filter((x) => x.id !== detail.id),
      })
    );
  };

  return (
    <>
      {loading ? (
        <div className={classes.center_div}>
          <CircularProgress />
        </div>
      ) : (
        details.map((detail, index) => (
          <div key={index}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <Avatar src={detail.itemCategory.icon} />
              </Grid>
              <Grid item xs>
                <Typography variant="body2" component="p">
                  {detail.itemDescription}
                </Typography>
                <Typography variant="caption" component="p">
                  {detail.fullCode}
                </Typography>
              </Grid>
              <Grid item xs>
                <QtyInputField
                  variant="outlined"
                  label="Qty"
                  name={`details[${index}].qty`}
                  margin="dense"
                  disabled={isSubmitting}
                  onChange={(e) => handleQtyChange(e, index)}
                />
              </Grid>
              <Grid item>
                <Tooltip title="Delete Item">
                  <IconButton
                    component="div"
                    size="small"
                    onClick={() => handleDeleteItem(detail)}
                    disabled={isSubmitting}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                {detail.personnel?.name && (
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>
                      <FaceIcon />
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" component="h2" align="center">
                        {detail.personnel?.name}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  spacing={1}
                >
                  <Grid item>
                    {detail.disc > 0 && (
                      <Typography
                        align="right"
                        variant="caption"
                        color="secondary"
                        component="span"
                      >
                        {`Disc: IDR ${detail.disc} `}
                      </Typography>
                    )}
                    <Typography
                      className={classes.price}
                      variant="body2"
                      component="span"
                      align="right"
                    >
                      IDR {detail.price.toLocaleString("en-US")}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="flex-end">
                  <Grid item>
                    <Typography align="right" variant="subtitle1" component="p">
                      Total: {detail.totalAmount.toLocaleString("en-US")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider />
          </div>
        ))
      )}
    </>
  );
});
