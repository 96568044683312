import React from "react";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { observer } from "mobx-react-lite";
import { useStore } from "../stores/store";
import { Typography } from "@material-ui/core";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default observer(function SnackbarContainer() {
  const { snackbarStore } = useStore();
  const { closeSnackbar, snackbar } = snackbarStore;

  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    closeSnackbar();
  };

  return (
    <Snackbar
      open={snackbar.open}
      autoHideDuration={snackbar.hideDuration}
      onClose={handleClose}
    >
      {snackbar.message ? (
        <Alert onClose={handleClose} severity={snackbar.severity}>
          {Array.isArray(snackbar.message)
            ? snackbar.message.map((x, index) => <div key={index}>{x}</div>)
            : snackbar.message}
        </Alert>
      ) : (
        <Alert onClose={handleClose} severity={snackbar.severity}>
          <>
            <Typography paragraph>
              {`Status Code: ${snackbar.messageServerError?.statusCode}`}
            </Typography>
            <Typography align="justify">
              {`Message: ${snackbar.messageServerError?.message}`}
            </Typography>
          </>
        </Alert>
      )}
    </Snackbar>
  );
});
