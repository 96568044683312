import {
  Grid,
  Paper,
  Toolbar,
  Typography,
  IconButton,
  TablePagination,
  useTheme,
  useMediaQuery,
  Tooltip,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useStyles } from "../../app/layout/style";
import { useStore } from "../../app/stores/store";
import AddIcon from "@material-ui/icons/Add";
import ItemCardSkeleton from "../Items/ItemCardSkeleton";
import ItemDeleteDialog from "../Items/ItemDeleteDialog";
import MedicalPersonnelCard from "./MedicalPersonnelCard";

export default observer(function MedicalPersonnelCardList() {
  const classes = useStyles();
  const theme = useTheme();
  const widthSizeMatch = useMediaQuery(theme.breakpoints.up("xl"));
  const { medicalPersonnelStore, dialogStore, snackbarStore } = useStore();
  const {
    pageNumber,
    pageSize,
    deletePerson,
    personList,
    loadingPerson,
    setPageNumber,
    setPageSize,
    pagination,
    rowsOptions,
    setRowsOptions,
  } = medicalPersonnelStore;
  const { openSnackbar } = snackbarStore;
  const { openDialogDelete, closeDialog } = dialogStore;
  const skeletonCardArray = Array(widthSizeMatch ? 8 : 6).fill("");

  useEffect(() => {
    if (personList.length === 0) {
      if (widthSizeMatch) {
        setRowsOptions([8, 8 * 2, 8 * 4, 8 * 8]);
        setPageSize(8);
      } else {
        setRowsOptions([6, 6 * 2, 6 * 4, 6 * 8]);
        setPageSize(6);
      }
    }
  }, [personList.length, widthSizeMatch, setRowsOptions, setPageSize]);

  const handlerDeletePerson = (id: string) => {
    openDialogDelete(<ItemDeleteDialog id={id} />, handleDelete);
  };

  const handleDelete = (id: string) => {
    deletePerson(id)
      .catch((error) => {
        closeDialog();
        openSnackbar(error, "error");
      })
      .then((message) => {
        if (message !== undefined) openSnackbar(message, "success");
        closeDialog();
      });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNumber(1);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Paper className={classes.paperCard}>
          <Toolbar>
            <Typography
              className={classes.title}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              {loadingPerson ? (
                <Skeleton variant="text" width="30%" />
              ) : (
                "Person List"
              )}
            </Typography>
            {loadingPerson ? (
              <Skeleton variant="circle" width={30} height={30} />
            ) : (
              <Tooltip title="Create">
                <IconButton
                  component={Link}
                  to={"/medicalpersonnels/form"}
                  color="primary"
                  size="medium"
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            )}
          </Toolbar>
        </Paper>
      </Grid>
      {loadingPerson
        ? skeletonCardArray.map((person, index) => (
            <Grid key={index} item xl={3} lg={4} md={6} sm={12} xs={12}>
              <ItemCardSkeleton />
            </Grid>
          ))
        : personList.map(([id, person]) => (
            <Grid key={id} item xl={3} lg={4} md={6} sm={12} xs={12}>
              <MedicalPersonnelCard
                person={person}
                deletePerson={handlerDeletePerson}
              />
            </Grid>
          ))}
      <Grid item xs={12}>
        <Paper className={classes.transferList}>
          <TablePagination
            rowsPerPageOptions={rowsOptions}
            component="div"
            count={pagination === null ? 0 : pagination.totalItems}
            page={pageNumber}
            onPageChange={handleChangePage}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
    </Grid>
  );
});
