import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { Item } from "../../app/models/item";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useStyles } from "../../app/layout/style";
import React from "react";
import { Link } from "react-router-dom";

interface Props {
  item: Item;
  deleteItem: (id: string) => void;
}

export default observer(function ItemCard({ item, deleteItem }: Props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDelete = (id: string) => {
    setAnchorEl(null);
    deleteItem(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar
            src={`${item.itemCategory.icon}`}
            className={classes.large}
          ></Avatar>
        }
        action={
          <>
            <IconButton aria-label="settings" onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem component={Link} to={`/items/${item.id}`}>
                Update
              </MenuItem>
              <MenuItem onClick={() => handleDelete(item.id)}>Delete</MenuItem>
            </Menu>
          </>
        }
        title={item.fullCode}
        subheader={item.itemCategory.description}
      />
      <CardContent>
        <Typography variant="body1">
          {item.itemType.description} - {item.itemDescription}
        </Typography>
        <Typography variant="body1"></Typography>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
          spacing={2}
        >
          <Grid item>
            <Typography variant="body1" color="secondary">
              Disc: {item.itemDisc.toLocaleString("EN-US")}%
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              {item.itemPrice.toLocaleString("EN-US")}
            </Typography>
          </Grid>
        </Grid>
        {!item.isActive && (
          <Chip
            icon={<SentimentVeryDissatisfiedIcon />}
            variant="outlined"
            color="secondary"
            label="Deleted"
          />
        )}
      </CardContent>
    </Card>
  );
});
