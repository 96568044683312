import {
  Popper,
  Grow,
  Paper,
  Button,
  Grid,
  makeStyles,
  Theme,
  createStyles,
  Typography,
  Divider,
} from "@material-ui/core";
import React, { useEffect } from "react";
import FilterListIcon from "@material-ui/icons/FilterList";
import { useStyles } from "../../app/layout/style";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { Form, Formik } from "formik";
import SearchFilter from "../../app/common/form/SearchFilter";
import { SalesReportSearch } from "../../app/models/sale";
import DateInputField from "../../app/common/form/DateInputFIeld";
import Dropdown2 from "../../app/common/form/Dropdown2";

const useLocalStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiTextField-root": {
        width: "40ch",
      },
    },
  })
);

export default observer(function ReportSalesFilter() {
  const classes = useStyles();
  const useLocalClasses = useLocalStyles();
  const { salesReportStore, itemStore } = useStore();
  const { setPredicate, predicate, loading } = salesReportStore;
  const { itemCategories, loadCategories, loadItemTypes, loadingItem } =
    itemStore;
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState<SalesReportSearch>(
    new SalesReportSearch()
  );
  const anchorRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    loadCategories();
  }, [loadItemTypes, loadCategories]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    setSearch(
      new SalesReportSearch({
        startDate:
          predicate.get("startDate") === undefined ||
          predicate.get("startDate") === ""
            ? new Date()
            : new Date(predicate.get("startDate")!),
        endDate:
          predicate.get("endDate") === undefined ||
          predicate.get("endDate") === ""
            ? new Date()
            : new Date(predicate.get("endDate")!),
        itemCategoryId:
          predicate.get("itemCategoryId") === undefined ||
          predicate.get("itemCategoryId") === ""
            ? "All"
            : predicate.get("itemCategoryId")!,
        itemTypeId:
          predicate.get("itemTypeId") === undefined ||
          predicate.get("itemTypeId") === ""
            ? "All"
            : predicate.get("itemTypeId")!,
        itemDescription:
          predicate.get("itemDescription") === undefined
            ? ""
            : predicate.get("itemDescription")!,
      })
    );
  };

  const handleSearch = (
    values: SalesReportSearch,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    setSubmitting(false);
    setPredicate(values);
    handleToggle();
  };

  const onCategoryChangeHandler = (
    setFieldValue: (
      field: string,
      value: string,
      shouldValidate?: boolean
    ) => void
  ) => {
    setFieldValue("itemTypeId", "All", true);
  };

  return (
    <div ref={anchorRef} className={useLocalClasses.root}>
      <Button color="primary" size="small" onClick={handleToggle}>
        Filter <FilterListIcon />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        placement="bottom-end"
        style={{ zIndex: 3 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-end" ? "right top" : "right bottom",
            }}
          >
            <Paper className={classes.form}>
              <Typography variant="subtitle2">Filter</Typography>
              <Divider />
              <Formik
                enableReinitialize
                initialValues={search}
                onSubmit={(values, { setSubmitting }) =>
                  handleSearch(values, setSubmitting)
                }
              >
                {({ handleSubmit, values, isSubmitting, setFieldValue }) => (
                  <Form onSubmit={handleSubmit} autoComplete="off">
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      spacing={1}
                      className={useLocalClasses.root}
                    >
                      <Grid item>
                        <DateInputField
                          label="Start Date"
                          name="startDate"
                          maxDate={values.endDate}
                          disabled={isSubmitting || loading}
                          inputVariant="outlined"
                          margin="dense"
                        />
                      </Grid>
                      <Grid item>
                        <DateInputField
                          label="End Date"
                          name="endDate"
                          minDate={values.startDate}
                          disabled={isSubmitting || loading}
                          inputVariant="outlined"
                          margin="dense"
                        />
                      </Grid>
                      <Grid item>
                        <Dropdown2
                          name="itemCategoryId"
                          options={itemCategories.map((x) => {
                            return { text: x.description, value: x.id };
                          })}
                          label="Item Category"
                          variant="outlined"
                          margin="dense"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          displayDefaultSelected
                          titleDefaultSelected={"All Selected"}
                          disabled={isSubmitting || loadingItem}
                          onChange={() =>
                            onCategoryChangeHandler(setFieldValue)
                          }
                        />
                      </Grid>
                      <Grid item>
                        <Dropdown2
                          name="itemTypeId"
                          options={itemCategories
                            .find((x) => x.id === values.itemCategoryId)
                            ?.types.map((x) => {
                              return { text: x.description, value: x.id };
                            })}
                          label="Item Type"
                          variant="outlined"
                          margin="dense"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          displayDefaultSelected
                          titleDefaultSelected={"All Selected"}
                          disabled={isSubmitting || loadingItem}
                        />
                      </Grid>
                      <Grid item>
                        <SearchFilter
                          name="itemDescription"
                          label="Description"
                          variant="outlined"
                          margin="dense"
                          disabled={isSubmitting || loading}
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          color="primary"
                          variant="contained"
                          type="submit"
                        >
                          Search
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
});
