import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Route, Switch } from "react-router-dom";
import Dashboard from "../../features/Dashboard/Dashboard";
import { observer } from "mobx-react-lite";
import PrivateRouteWithMenu from "./PrivateRouteWithMenu";
import NotFound from "../../features/Errors/NotFound";
import Container from "@material-ui/core/Container/Container";
import Footer from "./Footer";
import MasterMenu from "../../features/MasterMenu/MasterMenu";
import User from "../../features/Users/User";
import Store from "../../features/Stores/Store";
import StockLocation from "../../features/StockLocations/StockLocation";
import ManageMenu from "../../features/ManageMenu/ManageMenu";
import Item from "../../features/Items/Item";
import LocationAccess from "../../features/LocationAccess/LocationAccess";
import PrivateRoute from "./PrivateRoute";
import ChangeProfile from "../../features/Profiles/ChangeProfile";
import StockControl from "../../features/StockControls/StockControl";
import POS from "../../features/Sales/POS";
import Invoice from "../../features/Sales/Invoice";
import CashFlow from "../../features/CashFlows/CashFlow";
import ReportStockBalance from "../../features/Reports/ReportStockBalance";
import Customer from "../../features/Customers/Customer";
import MedicalPersonnel from "../../features/MedicalPersonnels/MedicalPersonnel";
import MedicalPersonnelFee from "../../features/MedicalPersonnels/MedicalPersonnelFee";
import POSGeneral from "../../features/Sales/POSGeneral";
import NurseFee from "../../features/Nurses/NurseFee";
import Nurse from "../../features/Nurses/Nurse";
import ReportSales from "../../features/Reports/ReportSales";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    width: "100%",
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(2, 3, 0, 3),
  },
}));

export default observer(function Content() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container component="main" className={classes.content} maxWidth="xl">
        <div className={classes.toolbar} />
        <Switch>
          <PrivateRoute exact path="/" component={Dashboard} />
          <PrivateRouteWithMenu
            path="/registeruser"
            component={User}
            link="/registeruser"
          />
          <PrivateRouteWithMenu
            path="/mastermenu"
            component={MasterMenu}
            link="/mastermenu"
          />
          <PrivateRouteWithMenu path="/store" component={Store} link="/store" />
          <PrivateRouteWithMenu
            path="/stocklocation"
            component={StockLocation}
            link="/stocklocation"
          />
          <PrivateRouteWithMenu
            path="/managemenu"
            component={ManageMenu}
            link="/managemenu"
          />
          <PrivateRouteWithMenu path="/items" component={Item} link="/items" />
          <PrivateRouteWithMenu
            path="/customers"
            component={Customer}
            link="/customers"
          />
          <PrivateRouteWithMenu
            path="/medicalpersonnels"
            component={MedicalPersonnel}
            link="/medicalpersonnels"
          />
          <PrivateRouteWithMenu
            path="/nurses"
            component={Nurse}
            link="/nurses"
          />
          <PrivateRouteWithMenu
            path="/location-access"
            component={LocationAccess}
            link="/location-access"
          />
          <PrivateRouteWithMenu
            path="/stockcontrol"
            component={StockControl}
            link="/stockcontrol"
          />
          <PrivateRouteWithMenu path="/pos" component={POS} link="/pos" />
          <PrivateRouteWithMenu
            path={"pos/:id"}
            component={StockControl}
            link={"pos/:id"}
          />
          <PrivateRouteWithMenu
            path="/invoice"
            component={Invoice}
            link="/invoice"
          />
          <PrivateRouteWithMenu
            path="/cashflow"
            component={CashFlow}
            link="/cashflow"
          />
          <PrivateRouteWithMenu
            path="/report-stockbalance"
            component={ReportStockBalance}
            link="/report-stockbalance"
          />
          <PrivateRouteWithMenu
            path="/report-sales"
            component={ReportSales}
            link="/report-sales"
          />
          <PrivateRouteWithMenu
            path="/personnelfee"
            component={MedicalPersonnelFee}
            link="/personnelfee"
          />
          <PrivateRouteWithMenu
            path="/nursefee"
            component={NurseFee}
            link="/nursefee"
          />
          <PrivateRouteWithMenu
            path="/posgeneral"
            component={POSGeneral}
            link="/posgeneral"
          />
          <PrivateRoute path="/profile" component={ChangeProfile} />
          <Route component={NotFound} />
        </Switch>
      </Container>
      <Footer />
    </div>
  );
});
