import {
  CircularProgress,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import React from "react";
import PriceInputField, {
  QtyInputField,
} from "../../app/common/form/NumberInputField";
import { useStyles } from "../../app/layout/style";
import { ItemDropdown } from "../../app/models/item";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { SalesMedicalPersonnelValues } from "../../app/models/sale";
import DropDownAutoComplete from "../../app/common/form/DropDownAutoComplete";

interface Props {
  item: ItemDropdown;
  handleConfirm: (
    item: ItemDropdown,
    value: SalesMedicalPersonnelValues
  ) => void;
}

export default function POSItemDialog({ item, handleConfirm }: Props) {
  const classes = useStyles();
  const { dialogStore, medicalPersonnelStore } = useStore();
  const { persons, loadingPerson } = medicalPersonnelStore;
  const [personnel] = React.useState<SalesMedicalPersonnelValues>(
    new SalesMedicalPersonnelValues({
      medicalPersonnel: persons[0],
      price: item.itemPrice,
      disc: item.itemDisc,
      fee: persons[0].fee,
    })
  );
  const { closeDialog } = dialogStore;

  const schema = Yup.object({
    medicalPersonnel: Yup.object()
      .nullable()
      .required("Personnel is required."),
    price: Yup.number()
      .typeError("Price is must be a number.")
      .positive("Price is must be greater then zero")
      .required("Price is required."),
    disc: Yup.number()
      .typeError("Discount is must be a number.")
      .required("Discount is required."),
    fee: Yup.number()
      .typeError("Fee is must be a number.")
      .required("Fee is required."),
  });

  const handlePersonnelChange = (
    event: React.ChangeEvent<{}>,
    value: any,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    if (value) {
      setFieldValue("fee", value.fee);
    } else {
      setFieldValue("fee", 0);
    }
  };

  const handleFormSubmit = (value: SalesMedicalPersonnelValues) => {
    let newItem: ItemDropdown = {
      ...item,
      itemPrice: value.price as number,
      itemDisc: value.disc as number,
    };
    handleConfirm(newItem, value);
    closeDialog();
  };

  return (
    <Formik
      validationSchema={schema}
      enableReinitialize
      initialValues={personnel}
      onSubmit={(value) => handleFormSubmit(value)}
    >
      {({ handleSubmit, isSubmitting, setFieldValue }) => (
        <Form onSubmit={handleSubmit} autoComplete="off">
          <DialogTitle id="form-dialog-title">{`(${item.fullCode}) - ${item.itemDescription}`}</DialogTitle>
          <DialogContent>
            {!item.itemCategory.haveStock && (
              <DropDownAutoComplete
                options={persons}
                name="medicalPersonnel"
                disabled={isSubmitting || loadingPerson}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                onChange={(event, value) =>
                  handlePersonnelChange(event, value, setFieldValue)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Medical Personnel"
                    placeholder="Please select any medical personnel"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            )}
            <Grid container direction="row" spacing={2}>
              <Grid item>
                <PriceInputField
                  variant="standard"
                  label="Price"
                  name="price"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  disabled={isSubmitting || loadingPerson}
                />
              </Grid>
              <Grid item xs>
                <PriceInputField
                  variant="standard"
                  label="Discount Value"
                  name="disc"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  disabled={isSubmitting || loadingPerson}
                />
              </Grid>
              {!item.itemCategory.haveStock && (
                <Grid item xs>
                  <QtyInputField
                    variant="standard"
                    label="Fee"
                    name="fee"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    andorment="%"
                    margin="normal"
                    disabled={isSubmitting || loadingPerson}
                  />
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={closeDialog}
              color="primary"
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button type="submit" color="primary" disabled={isSubmitting}>
              {isSubmitting && (
                <CircularProgress
                  className={classes.progress}
                  size={16}
                  color="inherit"
                />
              )}
              Confirm
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
}
