import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import { Pagination } from "../models/pagination";
import {
  BankAccount,
  InvoiceListSearch,
  PaymentType,
  Sale,
  SalesDetail,
  SalesFormValues,
  SalesPayment,
} from "../models/sale";
import { HeadCell, Order, SortBy } from "../models/table";

export default class SalesStore {
  documentRegistry = new Map<string, Sale>();
  headCells: HeadCell<any>[] = [];
  paymentTypes: PaymentType[] = [];
  bankAccounts: BankAccount[] = [];
  loadingSales = false;
  loadingInvoiceList = false;
  loadingSalesTrend = false;
  predicate = new Map<string, string>();
  rowsOptions = [6];
  pagination: Pagination | null = null;
  sortBy: Extract<keyof Sale, string> = "date";
  sortByCard: SortBy<Sale>[] = [
    { id: "date", label: "Date" },
    { id: "documentNo", label: "Invoice No." },
    { id: "customer", label: "Customer Name" },
  ];
  orderBy: Order = "desc";
  open = false;

  constructor() {
    makeAutoObservable(this);
    this.predicate.set("pageNumber", "1");
    this.predicate.set("sortBy", "");
    this.predicate.set("orderBy", this.orderBy);

    reaction(
      () => this.predicate.keys(),
      () => this.loadDocuments()
    );
  }

  setPredicate = (search: InvoiceListSearch) => {
    Object.keys(search).forEach((key) => {
      this.predicate.delete(key);
    });
    this.predicate.set("id", search.id === "All" ? "" : search.id);
    this.predicate.set(
      "startDate",
      search.startDate.toLocaleDateString("EN-US")
    );
    this.predicate.set("endDate", search.endDate.toLocaleDateString("EN-US"));
    this.predicate.set("customerName", search.customerName);
    this.predicate.set("documentNo", search.documentNo);
  };

  setPageNumber = (value: number) => {
    this.predicate.delete("pageNumber");
    this.predicate.set("pageNumber", value.toString());
  };

  setPageSize = (value: number) => {
    this.predicate.delete("pageSize");
    this.predicate.set("pageSize", value.toString());
    // this.predicate.set("pageNumber", "1");
  };

  setSortBy = (value: Extract<keyof Sale, string>, order: Order) => {
    this.predicate.delete("sortBy");
    this.predicate.set("sortBy", value.toString());
    this.sortBy = value;
    this.predicate.delete("orderBy");
    this.predicate.set("orderBy", order);
    this.orderBy = order;
  };

  setSortByCard = (value: Extract<keyof Sale, string> | null) => {
    this.predicate.delete("sortBy");
    if (value !== null) this.predicate.set("sortBy", value.toString());
  };

  setOrderBy = (value: Order) => {
    this.predicate.delete("orderBy");
    this.predicate.set("orderBy", value.toString());
    this.orderBy = value;
  };

  setRowsOptions = (value: number[]) => {
    this.rowsOptions = value;
  };

  get axiosParams() {
    const params = new URLSearchParams();
    this.predicate.forEach((value, key) => {
      params.delete(key);
      params.append(key, value);
    });
    return params;
  }

  get pageNumber() {
    return Number(this.predicate.get("pageNumber")) - 1;
  }

  get pageSize() {
    return this.predicate.get("pageSize") === undefined
      ? 5
      : Number(this.predicate.get("pageSize"));
  }

  getSubTotal = (details?: SalesDetail[]) => {
    if (details === undefined) return 0;
    return details?.reduce(
      (total, currentData) => (total = total + currentData.totalAmount),
      0
    );
  };

  getDiscSubTotal = (discTotal?: number, details?: SalesDetail[]) => {
    if (details === undefined) return 0;
    let discSubTotal = details?.reduce((total, currentData) => {
      const amount = currentData.qty * currentData.price;
      const discTotal = amount - currentData.totalAmount;
      return (total = total + discTotal);
    }, 0);

    return (discTotal === undefined ? 0 : discTotal) + discSubTotal;
  };

  getTotalQty = (details?: SalesDetail[]) => {
    if (details === undefined) return 0;
    return details?.reduce(
      (total, currentData) => (total = total + currentData.qty),
      0
    );
  };

  getTotalPayment = (payments: SalesPayment[]) => {
    return payments.reduce(
      (total, currentData) => (total = total + currentData.amount),
      0
    );
  };

  loadPaymentType = async () => {
    this.loadingSales = true;

    try {
      const result = await agent.Sales.paymentTypes();
      runInAction(() => {
        this.paymentTypes = result;
        this.setDocumentHeader();
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingSales = false));
    }
  };

  loadBankAccount = async () => {
    this.loadingSales = true;

    try {
      const result = await agent.Sales.bankAccounts();
      runInAction(() => {
        this.bankAccounts = result;
      });
      return result[0].id;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingSales = false));
    }
  };

  loadDocuments = async () => {
    this.loadingInvoiceList = true;
    this.open = true;

    try {
      const result = await agent.Sales.list(this.axiosParams);
      runInAction(() => {
        this.documentRegistry.clear();
        result.data.forEach((item) => {
          let itemResult = {
            ...item,
            details: this.sortDetails(item.details),
          };
          this.documentRegistry.set(item.id, itemResult);
        });
        this.pagination = result.pagination;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingInvoiceList = false));
    }
  };

  private setDocumentHeader() {
    this.headCells = [
      {
        id: "date",
        align: "left",
        disablePadding: false,
        label: "Date",
        disableSort: false,
        skeletonShape: "text",
      },
      {
        id: "documentNo",
        align: "left",
        disablePadding: false,
        label: "Document No.",
        disableSort: false,
        skeletonShape: "text",
      },
      {
        id: "customerName",
        align: "left",
        disablePadding: false,
        label: "Customer",
        disableSort: false,
        skeletonShape: "text",
      },
    ];
    this.headCells.push({
      id: "total_amount",
      align: "right",
      disablePadding: false,
      label: "Total Amount",
      disableSort: true,
      skeletonShape: "text",
    });
    this.paymentTypes.forEach((payment) => {
      this.headCells.push({
        id: payment.type,
        align: "right",
        disablePadding: false,
        label: payment.type,
        disableSort: true,
        skeletonShape: "text",
      });
    });
    this.headCells.push(
      {
        id: "slip",
        align: "center",
        disablePadding: true,
        label: "",
        disableSort: true,
        skeletonShape: "rect",
      },
      {
        id: "details",
        align: "center",
        disablePadding: true,
        label: "",
        disableSort: true,
        skeletonShape: "rect",
      }
    );
  }

  get documentList() {
    return Array.from(this.documentRegistry.values());
  }

  loadDocument = async (id: string) => {
    this.loadingSales = true;

    try {
      const result = await agent.Sales.details(id);
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingSales = false));
    }
  };

  createSales = async (document: SalesFormValues) => {
    try {
      const result = await agent.Sales.create(document);
      return { message: "Create sales document is success!", sales: result };
    } catch (error) {
      throw error;
    }
  };

  updateSales = async (document: SalesFormValues) => {
    try {
      const result = await agent.Sales.update(document);
      runInAction(() => {
        if (this.documentRegistry.size > 0)
          this.documentRegistry.set(result.id, result);
      });
      return { message: "Update sales document is success!", sales: result };
    } catch (error) {
      throw error;
    }
  };

  deleteSales = async (ids: string[]) => {
    this.loadingSales = true;

    try {
      await agent.Sales.delete(ids).then(() => {
        runInAction(() => {
          ids.forEach((x) => {
            this.documentRegistry.delete(x);
          });
        });
      });
      return "Delete document(s) success!";
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingSales = false));
    }
  };

  sortDetails = (details: SalesDetail[]) => {
    return details.sort((a, b) => {
      if (a.itemCode > b.itemCode) return 1;
      if (b.itemCode > a.itemCode) return -1;
      return 0;
    });
  };
}
