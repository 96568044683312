import { observer } from "mobx-react-lite";
import React from "react";
import { SalesReport } from "../../app/models/sale";
import moment from "moment";
import {
  StyledTableRow,
  StyledTableCell,
} from "../../app/common/table/EnhancedTableHead";

interface Props {
  row: SalesReport;
}

export default observer(function ReportSalesTableRow(props: Props) {
  const { row } = props;

  return (
    <StyledTableRow>
      <StyledTableCell>
        {moment(row.date).format("DD-MMM-yyyy")}
      </StyledTableCell>
      <StyledTableCell>{row.documentNo}</StyledTableCell>
      <StyledTableCell>{row.itemCategory.description}</StyledTableCell>
      <StyledTableCell>{row.itemType.description}</StyledTableCell>
      <StyledTableCell>{row.itemCode}</StyledTableCell>
      <StyledTableCell>{row.itemDescription}</StyledTableCell>
      <StyledTableCell align="right">{row.itemDisc.toLocaleString()}</StyledTableCell>
      <StyledTableCell align="center">{row.qty.toLocaleString()}</StyledTableCell>
      <StyledTableCell align="right">{row.totalAmount.toLocaleString()}</StyledTableCell>
    </StyledTableRow>
  );
});
