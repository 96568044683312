import Paper from "@material-ui/core/Paper";
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import { v4 as uuid } from "uuid";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Dropdown from "../../app/common/form/Dropdown";
import { useStyles } from "../../app/layout/style";
import { useStore } from "../../app/stores/store";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CashFlowFormValues } from "../../app/models/cashFlow";
import PriceInputField from "../../app/common/form/NumberInputField";
import InputField from "../../app/common/form/InputField";
import DateInputField from "../../app/common/form/DateInputFIeld";

export default observer(function CashFlowForm() {
  const classes = useStyles();
  const { cashFlowStore, snackbarStore, storeStore, salesStore } = useStore();
  const {
    loadCashflowType,
    cashFlowTypes: cashFlowType,
    loadingCashflow,
    loadDocument,
    createCashflow,
    updateCashflow,
  } = cashFlowStore;
  const { loadStoresGranted, storeDropDown } = storeStore;
  const { openSnackbar } = snackbarStore;
  const { loadBankAccount, bankAccounts } = salesStore;
  const [cashflow, setCashflow] = useState<CashFlowFormValues>(
    new CashFlowFormValues()
  );
  const history = useHistory();
  let { id } = useParams<{ id: string }>();

  useEffect(() => {
    loadStoresGranted();
    loadCashflowType();
    loadBankAccount();

    if (id) {
      loadDocument(id).then((result) => {
        setCashflow(new CashFlowFormValues(undefined, result));
      });
    }
  }, [id, loadDocument, loadStoresGranted, loadCashflowType, loadBankAccount]);

  const schema = Yup.object({
    date: Yup.date().required("Date is required."),
    storeId: Yup.string().required("Store field is required."),
    bankAccountId: Yup.string().required("Bank account id is required."),
    description: Yup.string().required(
      "Move to stock location field is required."
    ),
    amount: Yup.number()
      .typeError("Allocation is must be a number.")
      .positive("Quantity is must be greater than zero.")
      .required("Allocation is required."),
  });

  const handleFormSubmit = (
    value: CashFlowFormValues,
    resetForm: () => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    if (!value.id) {
      let newCashflow = {
        ...value,
        id: uuid(),
      };
      createCashflow(newCashflow)
        .catch((error) => {
          openSnackbar(error, "error");
          setSubmitting(false);
        })
        .then((message) => {
          if (message !== undefined) {
            openSnackbar(message, "success");
            setCashflow(new CashFlowFormValues());
            resetForm();
          }
        });
    } else {
      updateCashflow(value)
        .catch((error) => {
          openSnackbar(error, "error");
          setSubmitting(false);
        })
        .then((message) => {
          if (message !== undefined) {
            openSnackbar(message, "success");
            history.push("/cashflow");
          }
        });
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.form}>
            <Typography variant="h5">
              Form {id ? "Update" : "Create"}
            </Typography>
            <Divider />
            <Formik
              validationSchema={schema}
              enableReinitialize
              initialValues={cashflow}
              onSubmit={(values, { resetForm, setSubmitting }) =>
                handleFormSubmit(values, resetForm, setSubmitting)
              }
            >
              {({ handleSubmit, isSubmitting, isValid, dirty }) => (
                <Form onSubmit={handleSubmit} autoComplete="off">
                  <DateInputField
                    label="Date"
                    name="date"
                    maxDate={new Date()}
                    placeholder="Please input start date"
                    disabled={isSubmitting || loadingCashflow}
                    inputVariant={"standard"}
                    margin="normal"
                  />
                  <Dropdown
                    options={storeDropDown.map((x) => {
                      return { text: x.name, value: x.id };
                    })}
                    name="storeId"
                    placeholder="Store"
                    label="Store"
                    disabled={isSubmitting || loadingCashflow}
                  />
                  <Dropdown
                    options={cashFlowType.map((x) => {
                      return { text: x.description, value: x.id };
                    })}
                    name="cashFlowTypeId"
                    placeholder="Cashflow Type"
                    label="Cashflow Type"
                    disabled={isSubmitting || loadingCashflow}
                  />
                  <Dropdown
                    options={bankAccounts.map((x) => {
                      return {
                        text: `${x.owner} - ${x.bankName} - ${x.accountNo}`,
                        value: x.id,
                      };
                    })}
                    name="bankAccountId"
                    placeholder="Bank Account"
                    label="Bank Account"
                    disabled={isSubmitting || loadingCashflow}
                  />
                  <InputField
                    variant="standard"
                    label="Description"
                    name="description"
                    multiline
                    minRows={4}
                    placeholder="Please input spending description"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={isSubmitting || loadingCashflow}
                  />
                  <PriceInputField
                    variant="standard"
                    label="Amount"
                    name="amount"
                    margin="normal"
                    disabled={isSubmitting || loadingCashflow}
                  />
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="stretch"
                    spacing={1}
                  >
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isSubmitting || !isValid || !dirty}
                      >
                        {isSubmitting && (
                          <CircularProgress
                            className={classes.progress}
                            size={16}
                            color="inherit"
                          />
                        )}
                        Save
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="default"
                        component={Link}
                        to="/cashflow"
                        disabled={isSubmitting || loadingCashflow}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
});
