import { Avatar, Button, Grid, Paper, Typography } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useStyles } from "../../app/layout/style";
import { Customer } from "../../app/models/customer";

interface Props {
  customer: Customer;
  handleSelectCustomer: (customer: Customer) => void;
  closeDialog: () => void;
}

export default observer(function POSCustomerCard(props: Props) {
  const classes = useStyles();
  const { customer, handleSelectCustomer, closeDialog } = props;

  const handleSelect = (customer: Customer) => {
    handleSelectCustomer(customer);
    closeDialog();
  };

  return (
    <Paper
      style={{ height: "100%" }}
      variant="outlined"
      className={classes.content}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Grid item>
          <Avatar className={classes.avatar}>
            {customer.customerName.substring(0, 1)}
          </Avatar>
        </Grid>
        <Grid item xs>
          <Typography variant="subtitle1">{customer.customerName}</Typography>
          <Typography variant="caption" color="textSecondary">
            {customer.phoneNo}
          </Typography>
        </Grid>
        <Grid item>
          <Button onClick={() => handleSelect(customer)} color="primary">
            Select
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
});
