import {
  Popper,
  Grow,
  Paper,
  Button,
  Grid,
  makeStyles,
  Theme,
  createStyles,
  Typography,
  Divider,
  ClickAwayListener,
} from "@material-ui/core";
import React from "react";
import FilterListIcon from "@material-ui/icons/FilterList";
import { useStyles } from "../../app/layout/style";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { Form, Formik } from "formik";
import { StockBalanceSearch } from "../../app/models/inventories";
import SearchFilter from "../../app/common/form/SearchFilter";

const useLocalStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiTextField-root": {
        width: "40ch",
      },
    },
  })
);

export default observer(function ReportStockBalanceFilter() {
  const classes = useStyles();
  const useLocalClasses = useLocalStyles();
  const { inventoriesStore } = useStore();
  const { setPredicate, predicate } = inventoriesStore;
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState<StockBalanceSearch>(
    new StockBalanceSearch()
  );
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    setSearch(
      new StockBalanceSearch({
        itemCode:
          predicate.get("itemCode") === undefined
            ? ""
            : predicate.get("itemCode")!,
        date: new Date(),
        itemCategory:
          predicate.get("itemCategory") === undefined
            ? ""
            : predicate.get("itemCategory")!,
        itemType:
          predicate.get("itemType") === undefined
            ? ""
            : predicate.get("itemType")!,
        itemDescription:
          predicate.get("itemDescription") === undefined
            ? ""
            : predicate.get("itemDescription")!,
      })
    );
  };

  const handleSearch = (values: StockBalanceSearch) => {
    setPredicate(values);
    handleToggle();
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div ref={anchorRef} className={useLocalClasses.root}>
        <Button color="primary" size="small" onClick={handleToggle}>
          Filter <FilterListIcon />
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          transition
          placement="bottom-end"
          style={{ zIndex: 3 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-end" ? "right top" : "right bottom",
              }}
            >
              <Paper className={classes.form}>
                <Typography variant="subtitle2">Filter</Typography>
                <Divider />
                <Formik
                  enableReinitialize
                  initialValues={search}
                  onSubmit={(values) => handleSearch(values)}
                >
                  {({ handleSubmit }) => (
                    <Form onSubmit={handleSubmit} autoComplete="off">
                      <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                        className={useLocalClasses.root}
                      >
                        <Grid item>
                          <SearchFilter
                            name="itemCode"
                            label="Item Code"
                            variant="outlined"
                            margin="dense"
                          />
                        </Grid>
                        <Grid item>
                          <SearchFilter
                            name="itemCategory"
                            label="Category"
                            variant="outlined"
                            margin="dense"
                          />
                        </Grid>
                        <Grid item>
                          <SearchFilter
                            name="itemType"
                            label="Type"
                            variant="outlined"
                            margin="dense"
                          />
                        </Grid>
                        <Grid item>
                          <SearchFilter
                            name="itemDescription"
                            label="Description"
                            variant="outlined"
                            margin="dense"
                          />
                        </Grid>
                        <Grid item>
                          <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                          >
                            Search
                          </Button>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
});
