import {
  Card,
  CardHeader,
  Avatar,
  CardContent,
  Typography,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { StockBalance } from "../../app/models/inventories";

interface Props {
  item: StockBalance;
}

export default observer(function ReportStockBalanceCard({ item }: Props) {
  return (
    <Card style={{ height: "100%" }}>
      <CardHeader
        avatar={
          <Avatar src={`${item.icon}`}></Avatar>
        }
        title={item.itemDescription}
        subheader={item.itemCode}
      />
      <CardContent>
        <Typography variant="subtitle1">{item.itemCategory}</Typography>
        <Typography variant="body1">
          {item.qty.toLocaleString("EN-US")}
        </Typography>
      </CardContent>
    </Card>
  );
});
