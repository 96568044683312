import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useStore } from "../../app/stores/store";
import { Typography, Divider, Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Helmet } from "react-helmet";
import ReportSalesFilter from "./ReportSalesFilter";
import ReportSalesTable from "./ReportSalesTable";

export default observer(function ReportSales() {
  const { menuStore, salesReportStore } = useStore();
  const { loadingMenu } = menuStore;
  const { salesReport, loadSalesReport } = salesReportStore;

  useEffect(() => {
    if (salesReport.length === 0) loadSalesReport();
  }, [loadSalesReport, salesReport.length]);

  return (
    <>
      {loadingMenu ? (
        <>
          <Typography variant="h4">
            <Skeleton width="50%" />
          </Typography>
          <Divider />
        </>
      ) : (
        <>
          <Helmet>
            <title>PERSADA MEDIC - Report Sales</title>
          </Helmet>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h4">Report Sales</Typography>
            </Grid>
            <Grid item>
              <ReportSalesFilter />
            </Grid>
          </Grid>
          <Divider />
          <Grid container>
            <Grid item xs={12}>
              <ReportSalesTable />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
});
