import { observer } from "mobx-react-lite";
import { Switch, useRouteMatch } from "react-router-dom";
import PrivateRouteWithMenu from "../../app/layout/PrivateRouteWithMenu";
import Title from "../../app/layout/Title";
import MedicalPersonnelForm from "./MedicalPersonnelForm";
import MedicalPersonnelList from "./MedicalPersonnelList";

export default observer(function MedicalPersonnel() {
  let { path } = useRouteMatch();

  return (
    <>
      <Title menuName="Register Medical Personnel" />
      <Switch>
        <PrivateRouteWithMenu
          exact
          path={path}
          component={MedicalPersonnelList}
          link={path}
        />
        <PrivateRouteWithMenu
          path={`${path}/form`}
          component={MedicalPersonnelForm}
          link={path}
        />
        <PrivateRouteWithMenu
          path={`${path}/:id`}
          component={MedicalPersonnelForm}
          link={path}
        />
      </Switch>
    </>
  );
});
