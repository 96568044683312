import {
  Avatar,
  Button,
  FormControl,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import DateInputField from "../../app/common/form/DateInputFIeld";
import Dropdown2 from "../../app/common/form/Dropdown2";
import StoreIcon from "@material-ui/icons/Store";
import TodayIcon from "@material-ui/icons/Today";
import FaceIcon from "@material-ui/icons/Face";
import { usePosStyles } from "../../app/layout/pos";
import { useStore } from "../../app/stores/store";
import React from "react";
import POSCustomerDialog from "./POSCustomerDialog";
import { Customer } from "../../app/models/customer";
import { FormikErrors, FormikTouched } from "formik";
import { SalesFormValues } from "../../app/models/sale";
import ClearIcon from "@material-ui/icons/Clear";
import { useStyles } from "../../app/layout/style";
import MedicalPersonnelAutoComplete from "./MedicalPersonnelAutoComplete";
import { MedicalPersonnel } from "../../app/models/medicalPersonnel";
import NurseAutoComplete from "./NurseAutoComplete";
import { Nurse } from "../../app/models/nurses";

interface Props {
  sales: SalesFormValues;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  setSales: (sales: SalesFormValues) => void;
  customer: Customer | null;
  setCustomer: (customer: Customer | null) => void;
  person: MedicalPersonnel | null;
  setPerson: (person: MedicalPersonnel | null) => void;
  nurse: Nurse | null;
  setNurse: (nurse: Nurse | null) => void;
  isSubmitting: boolean;
  errors: FormikErrors<SalesFormValues>;
  touched: FormikTouched<SalesFormValues>;
  setFieldTouched: (
    field: string,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined
  ) => void;
}

export default observer(function POSGeneralTransaction(props: Props) {
  const local = usePosStyles();
  const classes = useStyles();
  const {
    sales,
    setSales,
    customer,
    setCustomer,
    person,
    setPerson,
    nurse,
    setNurse,
    isSubmitting,
    errors,
    setFieldValue,
    touched,
    setFieldTouched,
  } = props;
  const {
    medicalPersonnelStore,
    nurseStore,
    salesStore,
    itemStore,
    storeStore,
    userStore,
    dialogStore,
  } = useStore();
  const { persons, loadingPerson } = medicalPersonnelStore;
  const { persons: nurses, loadingPerson: loadingNurse } = nurseStore;
  const { loadingSales } = salesStore;
  const { loadingItem } = itemStore;
  const { storeDropDown, loadingStore } = storeStore;
  const { openDialog } = dialogStore;
  const { user } = userStore;
  const role = React.useMemo(
    () => user?.roles.find((x) => x.name === "Developer"),
    [user?.roles]
  );

  const handleSearchCustomer = () => {
    openDialog(
      <POSCustomerDialog handleSelectCustomer={handleSelectCustomer} />,
      "lg",
      undefined,
      true
    );
  };

  const handleSelectCustomer = (customer: Customer) => {
    setCustomer(customer);
    setSales({ ...sales, customerId: customer.id });
  };

  const handleClear = () => {
    setCustomer(null);
    setSales({ ...sales, customerId: "" });
  };

  const handlePersonnelChange = (value: MedicalPersonnel | null) => {
    if (value) setFieldValue("personnelId", value.id);
    else setFieldValue("personnelId", value);
  };

  const handleNurseChange = (value: Nurse | null) => {
    if (value) setFieldValue("nurseId", value.id);
    else setFieldValue("nurseId", value);
  };

  return (
    <Paper className={local.transaction}>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item>
          <TodayIcon />
        </Grid>
        <Grid item xs>
          <DateInputField
            label="Transaction Date"
            inputVariant="outlined"
            name="date"
            margin="dense"
            disabled={
              isSubmitting ||
              loadingItem ||
              loadingSales ||
              loadingStore ||
              role === undefined
            }
            maxDate={new Date()}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item>
          <StoreIcon />
        </Grid>
        <Grid item xs>
          <Dropdown2
            name="storeId"
            options={storeDropDown.map((x) => {
              return { text: x.name, value: x.id };
            })}
            label="Store"
            variant="outlined"
            margin="dense"
            InputLabelProps={{
              shrink: true,
            }}
            disabled={isSubmitting || loadingStore}
          />
        </Grid>
      </Grid>
      {/* <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item>
          <MoneyOffIcon />
        </Grid>
        <Grid item xs>
          <PriceInputField
            variant="outlined"
            label="Discount"
            name="disc"
            InputLabelProps={{
              shrink: true,
            }}
            margin="dense"
            disabled={isSubmitting || loadingStore}
          />
        </Grid>
      </Grid> */}
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item>
          <FaceIcon />
        </Grid>
        <Grid item xs>
          <MedicalPersonnelAutoComplete
            name="personnelId"
            options={persons}
            value={person}
            setValue={setPerson}
            loading={loadingPerson}
            onChange={handlePersonnelChange}
            setFieldValue={setFieldValue}
            error={errors.personnelId}
            touched={touched.personnelId}
            setFieldTouched={setFieldTouched}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item>
          <FaceIcon />
        </Grid>
        <Grid item xs>
          <NurseAutoComplete
            name="nurseId"
            options={nurses}
            value={nurse}
            setValue={setNurse}
            loading={loadingNurse}
            onChange={handleNurseChange}
            setFieldValue={setFieldValue}
            error={errors.nurseId}
            touched={touched.nurseId}
            setFieldTouched={setFieldTouched}
          />
        </Grid>
      </Grid>
      <FormControl fullWidth margin="dense">
        {customer === null ? (
          <>
            <Button
              fullWidth
              variant="contained"
              color={errors.customerId ? "secondary" : "primary"}
              className={local.save}
              onClick={handleSearchCustomer}
            >
              Search Customer
            </Button>
          </>
        ) : (
          <Paper variant="outlined" className={classes.content}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <Avatar className={classes.avatar}>
                  {customer.customerName.substring(0, 1)}
                </Avatar>
              </Grid>
              <Grid item xs>
                <Typography variant="subtitle1">
                  {customer.customerName}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {customer.phoneNo}
                </Typography>
                <input hidden name="customerId" />
              </Grid>
              <Grid item>
                <Tooltip title="Clear">
                  <IconButton onClick={handleClear} size="small">
                    <ClearIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Paper>
        )}
      </FormControl>
    </Paper>
  );
});
