import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useStyles } from "../../app/layout/style";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { useStore } from "../../app/stores/store";
import { v4 as uuid } from "uuid";
import { Avatar, Chip, Paper, Typography } from "@material-ui/core";
import { QtyInputField } from "../../app/common/form/NumberInputField";
import {
  StockControlDetail,
  StockControlDetailValues,
  StockControlFormValues,
  StockTransactionType,
} from "../../app/models/stockControl";
import StockControlAutoComplete from "./StockControlAutoComplete";
import InputField from "../../app/common/form/InputField";
import { ItemDropdown } from "../../app/models/item";
import ItemsAutoComplete from "./ItemsAutoComplete";

interface Props {
  stockControl: StockControlFormValues;
  setStockControl: (stockOutgoing: StockControlFormValues) => void;
  details: StockControlDetail[];
  setDetails: (detail: StockControlDetail[]) => void;
  itemGroup: ItemDropdown[];
  setItemGroup: (itemGroup: ItemDropdown[]) => void;
  isSubmitting: boolean;
  loading: boolean;
}

export default observer(function StockControlFormAddDetails({
  stockControl,
  setStockControl,
  details,
  setDetails,
  itemGroup,
  setItemGroup,
  isSubmitting,
  loading,
}: Props) {
  const classes = useStyles();
  const { stockControlStore, itemStore } = useStore();
  const { stockTransactionType } = stockControlStore;
  const { itemsDropDown } = itemStore;
  const [valueType, setValueType] = useState<StockTransactionType | null>(null);
  const [valueItem, setValueItem] = useState<ItemDropdown | null>(null);

  const handleAddItems = () => {
    if (valueType && valueItem) {
      const id = uuid();
      setDetails(
        details.concat({
          id: id,
          itemId: valueItem.id,
          stockTransactionType: valueType,
          itemCode: valueItem.itemCode,
          itemDescription: valueItem.itemDescription,
          itemType: valueItem.itemType,
          itemCategory: valueItem.itemCategory,
          itemPrice: valueItem.itemPrice,
          itemDisc: valueItem.itemDisc,
          fullCode: valueItem.fullCode,
        } as StockControlDetail)
      );
      setItemGroup(itemGroup.concat(valueItem));
      setStockControl(
        new StockControlFormValues({
          ...stockControl,
          details: stockControl.details.concat(
            new StockControlDetailValues({
              id: id,
              stockControlId: stockControl.id,
              stockTransactionTypeId: valueType.id,
              itemId: valueItem.id,
              notes: "",
            })
          ),
        })
      );
      setValueItem(null);
    }
  };

  const handleDeleteItem = (detail: StockControlDetail) => {
    setDetails(details.filter((x) => x.id !== detail.id));
    setStockControl({
      ...stockControl,
      details: stockControl.details.filter((x) => x.id !== detail.id),
    });
    setItemGroup(itemGroup.filter((x) => x.fullCode !== detail.fullCode));
  };

  return (
    <div className={classes.paper}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        <Grid item lg={4} md={4} xs={12}>
          <Typography variant="h6" component="p">
            Add Details
          </Typography>
        </Grid>
        <Grid item lg={3} md={4} xs={12}>
          <StockControlAutoComplete
            options={stockTransactionType}
            value={valueType}
            setValue={setValueType}
            loading={isSubmitting || loading}
          />
        </Grid>
        <Grid item lg={5} md={4} xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs>
              <ItemsAutoComplete
                options={itemsDropDown.filter((x) => x.itemCategory.haveStock)}
                value={valueItem}
                setValue={setValueItem}
                loading={isSubmitting || loading}
                optionDisabled={itemGroup}
              />
            </Grid>
            <Grid item>
              <Tooltip title="Add Items">
                <IconButton size="small" onClick={handleAddItems}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {details.map((detail, index) => (
        <Paper key={index} className={classes.itemCard} variant="outlined">
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid item lg={5} md={8} xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Chip
                    variant="outlined"
                    color="primary"
                    label={detail.stockTransactionType.type}
                  />
                </Grid>
                <Grid item>
                  <Avatar src={`${detail.itemCategory.icon}`}></Avatar>
                </Grid>
                <Grid item xs>
                  <Typography variant="body2" component="p">
                    {detail.itemDescription}
                  </Typography>
                  <Typography variant="caption" component="p">
                    {detail.fullCode}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={3} md={4} xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs>
                  <QtyInputField
                    variant="outlined"
                    label="Qty"
                    name={`details[${index}].qty`}
                    margin="dense"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={isSubmitting || loading}
                    autoFocus
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={4} md={12} xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs>
                  <InputField
                    variant="outlined"
                    label="Notes"
                    name={`details[${index}].notes`}
                    multiline
                    minRows={4}
                    placeholder="Please input notes"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={isSubmitting || loading}
                    margin="dense"
                  />
                </Grid>
                <Grid item>
                  <Tooltip title="Delete Item">
                    <IconButton
                      size="small"
                      onClick={() => handleDeleteItem(detail)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      ))}
    </div>
  );
});
