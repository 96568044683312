import {
  Card,
  CardHeader,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  CardContent,
  Typography,
  Grid,
  Chip,
  Collapse,
  Divider,
  CardActions,
  Paper,
  createStyles,
  makeStyles,
  Theme,
  TablePagination,
} from "@material-ui/core";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React from "react";
import { Link } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Customer } from "../../app/models/customer";
import { useStyles } from "../../app/layout/style";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import AssignmentIcon from "@material-ui/icons/Assignment";
import CakeIcon from "@material-ui/icons/Cake";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import moment from "moment";
import { deepOrange, deepPurple } from "@material-ui/core/colors";

interface Props {
  customer: Customer;
  deleteCustomer: (id: string) => void;
}

const useLocalStyles = makeStyles((theme: Theme) =>
  createStyles({
    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
    },
    purple: {
      color: theme.palette.getContrastText(deepPurple[500]),
      backgroundColor: deepPurple[500],
    },
    detailCard: {
      marginTop: theme.spacing(1),
    },
  })
);

export default observer(function CustomerCard({
  customer,
  deleteCustomer,
}: Props) {
  const classes = useStyles();
  const local = useLocalStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const todaysDate = moment(new Date());
  const dob = moment(customer.dateOfBirth);
  const duration = moment.duration(todaysDate.diff(dob, "milliseconds"));
  const years = duration.years();
  const months = Math.floor(duration.asMonths()) - years * 12;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDelete = (id: string) => {
    setAnchorEl(null);
    deleteCustomer(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar className={local.purple}>
            {customer.customerName.substring(0, 1)}
          </Avatar>
        }
        action={
          <>
            <IconButton aria-label="settings" onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem component={Link} to={`/customers/${customer.id}`}>
                Update
              </MenuItem>
              <MenuItem onClick={() => handleDelete(customer.id)}>
                Delete
              </MenuItem>
            </Menu>
          </>
        }
        title={customer.customerName}
        subheader={`${customer.gender} - ${years} years and ${months} months`}
      />
      <CardContent>
        <Grid container direction="row" justifyContent="flex-start" spacing={2}>
          <Grid item>
            <CakeIcon />
          </Grid>
          <Grid item>
            <Typography variant="body1" component="p">
              {moment(customer.dateOfBirth).format("DD-MMM-yyyy")}
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="flex-start" spacing={2}>
          <Grid item>
            <ContactPhoneIcon />
          </Grid>
          <Grid item>
            <Typography variant="body1" component="p">
              {customer.phoneNo}
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="flex-start" spacing={2}>
          <Grid item>
            <LocationCityIcon />
          </Grid>
          <Grid item>
            <Typography variant="body1" component="p">
              {customer.address}
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="flex-start" spacing={2}>
          <Grid item>
            <AssignmentIcon />
          </Grid>
          <Grid item>
            <Typography variant="body1" component="p">
              {customer.notes}
            </Typography>
          </Grid>
        </Grid>
        {!customer.isActive && (
          <Chip
            icon={<SentimentVeryDissatisfiedIcon />}
            variant="outlined"
            color="secondary"
            label="Deleted"
          />
        )}
      </CardContent>
      <CardActions disableSpacing>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {customer.details
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row) => (
              <div key={row.id}>
                <Typography variant="caption" color="textSecondary">
                  {moment(row.date).format("MMMM DD, YYYY")}
                </Typography>
                <Divider />
                {row.details.map((detail) => (
                  <div className={local.detailCard}>
                    <Grid
                      container
                      direction="row"
                      spacing={1}
                      alignItems="center"
                    >
                      <Grid item>
                        <Avatar src={`${detail.itemCategory.icon}`} />
                      </Grid>
                      <Grid item xs>
                        {detail.itemDescription}
                      </Grid>
                      <Grid item>
                        IDR {detail.totalAmount.toLocaleString("EN-US")}
                      </Grid>
                    </Grid>
                    <Divider />
                  </div>
                ))}
              </div>
            ))}
          <Paper variant="outlined">
            <TablePagination
              rowsPerPageOptions={[5]}
              component="div"
              count={customer.details.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Show"
            />
          </Paper>
        </CardContent>
      </Collapse>
    </Card>
  );
});
