import {
  createTheme,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React from "react";
import { usePosStyles } from "../../app/layout/pos";
import { useStyles } from "../../app/layout/style";
import { MedicalPersonnel } from "../../app/models/medicalPersonnel";

type Props = {
  componentToPrint: React.MutableRefObject<HTMLDivElement | null>;
  person: MedicalPersonnel;
};

export default observer(function MedicalPersonnelFeeSlip(props: Props) {
  const classes = useStyles();
  const posStyles = usePosStyles();
  const { componentToPrint, person } = props;
  const totalFee = person.details.reduce(
    (total, currentData) => (total = total + currentData.feeAmount),
    0
  );
  let theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          type: "light",
        },
        typography: {
          fontFamily: `"Open Sans", "Helvetica", "Arial", sans-serif`,
          fontWeightRegular: 400,
        },
        overrides: {
          MuiDivider: {
            root: {
              margin: "8px 0px 8px 0px",
            },
          },
        },
      }),
    []
  );

  return (
    <ThemeProvider theme={theme}>
      <div
        ref={(el) => (componentToPrint.current = el)}
        className={posStyles.slip}
      >
        <Grid container direction="row" alignItems="center" spacing={1}>
          <Grid item>
            <img src="/persadamedic.jpeg" alt="logo" className={classes.logo} />
          </Grid>
          <Grid item>
            <Typography variant="h5">PERSADA MEDIC</Typography>
            <p>
              <Typography variant="body1">Fee Slip</Typography>
            </p>
          </Grid>
        </Grid>
        <Divider />
        <table>
          <tbody>
            <tr>
              <td>Name</td>
              <td>:</td>
              <td>{person.name}</td>
            </tr>
          </tbody>
        </table>
        <TableContainer
          component={Paper}
          style={{ margin: theme.spacing(1, 0) }}
        >
          <Table className={posStyles.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">Date</TableCell>
                <TableCell align="center">Invoice No.</TableCell>
                <TableCell align="center">Customer Name</TableCell>
                <TableCell align="center">Description</TableCell>
                <TableCell align="right">Transaction Amount</TableCell>
                <TableCell align="center">Fee (%)</TableCell>
                <TableCell align="right">Fee Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {person?.details.map((detail, index) => (
                <TableRow key={index}>
                  <TableCell align="center">{moment(detail.date).format("DD-MMM-yyyy")}</TableCell>
                  <TableCell align="center">{detail.documentNo}</TableCell>
                  <TableCell align="center">
                    {detail.customer}
                  </TableCell>
                  <TableCell align="center">{detail.itemDescription}</TableCell>
                  <TableCell align="right">
                    {detail.totalAmount.toLocaleString("EN-US")}
                  </TableCell>
                  <TableCell align="center">{detail.fee}</TableCell>
                  <TableCell align="right">
                    {detail.feeAmount.toLocaleString("EN-US")}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={4} align="right">
                  Grand Total:
                </TableCell>
                <TableCell align="right">{totalFee.toLocaleString()}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </ThemeProvider>
  );
});
