import { SalesDetail } from "./sale";

export const gender = ["MALE", "FEMALE"];

export interface Customer {
  id: string;
  customerName: string;
  phoneNo: string;
  address: string;
  dateOfBirth: Date;
  gender: string;
  notes: string;
  isActive: boolean;
  details: CustomerSales[];
}

export interface CustomerSales {
  id: string;
  date: Date;
  documentNo: string;
  details: SalesDetail[];
}

export class CustomerFormValues {
  id?: string = "";
  customerName: string = "";
  dateOfBirth: Date = new Date();
  gender: string = "";
  phoneNo: string = "";
  address: string = "";
  notes: string = "";
  isActive: boolean = true;

  constructor(customer?: CustomerFormValues) {
    if (customer) {
      this.id = customer.id;
      this.customerName = customer.customerName;
      this.phoneNo = customer.phoneNo;
      this.dateOfBirth = customer.dateOfBirth;
      this.gender = customer.gender;
      this.address = customer.address;
      this.notes = customer.notes;
      this.isActive = customer.isActive;
    }
  }
}
