import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import React from "react";
import Collapse from "@material-ui/core/Collapse";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Box from "@material-ui/core/Box";
import TableHead from "@material-ui/core/TableHead";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Avatar from "@material-ui/core/Avatar";
import moment from "moment";
import { StockControl } from "../../app/models/stockControl";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import { useStore } from "../../app/stores/store";

interface RowProps {
  row: StockControl;
  labelId: string;
  isItemSelected: boolean;
  handleClick: (event: React.MouseEvent<unknown>, id: string) => void;
  handlePost: (
    event: React.MouseEvent<unknown>,
    id: string,
    posted: boolean
  ) => void;
}

export default function StockControlTableRow({
  row,
  labelId,
  isItemSelected,
  handleClick,
  handlePost,
}: RowProps) {
  const [open, setOpen] = React.useState(false);
  const { userStore } = useStore();
  const { user } = userStore;
  const totalQty = row.details.reduce(
    (total, currentData) => (total = total + currentData.qty),
    0
  );
  const role = user?.roles.find((x) => x.name === "Developer");
  const restricted =
    new Date(row.date).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);

  return (
    <React.Fragment>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={row.id}
        selected={isItemSelected}
      >
        <TableCell padding="checkbox">
          {!row.posted && (
            <Checkbox
              checked={isItemSelected}
              onClick={(event) => handleClick(event, row.id)}
            />
          )}
        </TableCell>
        <TableCell padding="checkbox">
          {!row.posted && (
            <Tooltip title="Update">
              <IconButton
                component={Link}
                to={`/stockcontrol/${row.id}`}
                color="primary"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
        <TableCell component="th" id={labelId} scope="row">
          {moment(row.date).format("DD-MMM-yyyy")}
        </TableCell>
        <TableCell>{row.documentNo}</TableCell>
        <TableCell>{row.stockLocationName}</TableCell>
        <TableCell align="center">
          {!role ? (
            row.posted && restricted ? (
              "Posted"
            ) : (
              <Button
                onClick={(e) => handlePost(e, row.id, row.posted)}
                variant="outlined"
                color={row.posted ? "secondary" : "primary"}
              >
                {row.posted ? "Unpost" : "Post"}
              </Button>
            )
          ) : (
            <Button
              onClick={(e) => handlePost(e, row.id, row.posted)}
              variant="outlined"
              color={row.posted ? "secondary" : "primary"}
            >
              {row.posted ? "Unpost" : "Post"}
            </Button>
          )}
        </TableCell>
        {row.details.length > 0 ? (
          <TableCell>
            <IconButton size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        ) : (
          <TableCell></TableCell>
        )}
      </TableRow>
      {row.details.length > 0 && (
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={7}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box
                marginLeft={5}
                marginTop={1}
                marginRight={5}
                marginBottom={2}
              >
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox"></TableCell>
                      <TableCell padding="checkbox"></TableCell>
                      <TableCell align="center">Category</TableCell>
                      <TableCell align="center">Type</TableCell>
                      <TableCell>Item Code</TableCell>
                      <TableCell>Item Description</TableCell>
                      <TableCell align="right">Quantity</TableCell>
                      <TableCell>Notes</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.details.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell>
                          <Chip
                            variant="outlined"
                            color="primary"
                            label={item.stockTransactionType.type}
                          />
                        </TableCell>
                        <TableCell>
                          <Avatar src={`${item.itemCategory.icon}`}></Avatar>
                        </TableCell>
                        <TableCell align="center">
                          {item.itemCategory.description}
                        </TableCell>
                        <TableCell align="center">
                          {item.itemType.description}
                        </TableCell>
                        <TableCell>{item.fullCode}</TableCell>
                        <TableCell>{item.itemDescription}</TableCell>
                        <TableCell align="right">
                          {item.qty.toLocaleString()}
                        </TableCell>
                        <TableCell>{item.notes}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell align="right" colSpan={6}>
                        TOTAL
                      </TableCell>
                      <TableCell align="right">
                        {totalQty.toLocaleString()}
                      </TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}
