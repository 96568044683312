import { observer } from "mobx-react-lite";
import React from "react";
import { HeadCell, Order } from "../../app/models/table";
import { useStyles } from "../../app/layout/style";
import { TableHead, TableRow, TableCell, TableSortLabel } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

interface Props {
    headCells: HeadCell<any>[];
    loading: boolean;
    order: Order;
    orderBy: string;
    onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  }

export default observer(function ReportSalesTableHead(props: Props) {
    const classes = useStyles();
    const { headCells, loading, order, orderBy, onRequestSort } = props;
  
    if (loading) {
      return (
        <TableHead>
          <TableRow>
            {headCells.map((cell, index) => (
              <TableCell
                key={index}
                style={{ padding: "16px" }}
                padding={
                  cell.skeletonShape === "circle" || cell.skeletonShape === "rect"
                    ? "checkbox"
                    : "normal"
                }
              >
                {cell.skeletonShape !== "circle" && (
                  <Skeleton
                    variant="rect"
                    width={cell.skeletonShape === "rect" ? "100%" : "50%"}
                  />
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      );
    }
  
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) =>
            headCell.label === "" ? (
              <TableCell key={headCell.id} padding="checkbox"></TableCell>
            ) : (
              <TableCell
                key={headCell.id}
                align={headCell.align}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                {headCell.disableSort ? (
                  headCell.label.toLocaleUpperCase()
                ) : (
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={(e) => onRequestSort(e, headCell.id)}
                  >
                    {headCell.label.toLocaleUpperCase()}
                    {orderBy === headCell.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </span>
                    ) : null}
                  </TableSortLabel>
                )}
              </TableCell>
            )
          )}
        </TableRow>
      </TableHead>
    );
  });
  