import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import React, { useRef } from "react";
import Collapse from "@material-ui/core/Collapse";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ReceiptIcon from "@material-ui/icons/Receipt";
import Box from "@material-ui/core/Box";
import TableHead from "@material-ui/core/TableHead";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Avatar from "@material-ui/core/Avatar";
import moment from "moment";
import { Chip, Typography } from "@material-ui/core";
import { PaymentType, Sale } from "../../app/models/sale";
import { observer } from "mobx-react-lite";
import { HeadCell } from "../../app/models/table";
import { useStore } from "../../app/stores/store";
import ReactToPrint from "react-to-print";
import POSSlip from "./POSSlip";
import { useStyles } from "../../app/layout/style";

interface RowProps {
  row: Sale;
  headCells: HeadCell<any>[];
  dynamicRow: PaymentType[];
  labelId: string;
  isItemSelected: boolean;
  handleClick: (event: React.MouseEvent<unknown>, id: string) => void;
}

export default observer(function InvoiceTableRow({
  row,
  headCells,
  dynamicRow,
  labelId,
  isItemSelected,
  handleClick,
}: RowProps) {
  const classes = useStyles();
  const { userStore, salesStore } = useStore();
  const { user } = userStore;
  const { getSubTotal, getTotalQty, getTotalPayment } = salesStore;
  const [open, setOpen] = React.useState(false);
  const totalQty = getTotalQty(row.details);
  const subTotal = getSubTotal(row.details);
  const grandTotal = subTotal - row.disc;
  const totalPayment = getTotalPayment(row.payments);
  const role = user?.roles.find((x) => x.name === "Developer");
  const restricted = role
    ? false
    : new Date(row.date).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);
  const componentRef = useRef(null);

  return (
    <React.Fragment>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={row.id}
        selected={isItemSelected}
      >
        <TableCell padding="checkbox">
          {!restricted && (
            <Checkbox
              checked={isItemSelected}
              onClick={(event) => handleClick(event, row.id)}
            />
          )}
        </TableCell>
        <TableCell padding="checkbox">
          {!restricted && (
            <Tooltip title="Update">
              <IconButton
                component={Link}
                to={`/invoice/${row.id}?stat=${row.status}`}
                color="primary"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
        <TableCell component="th" id={labelId} scope="row">
          {moment(row.date).format("DD-MMM-yyyy")}
        </TableCell>
        <TableCell>{row.documentNo}</TableCell>
        <TableCell>{row.customer.customerName.toLocaleUpperCase()}</TableCell>
        <TableCell align="right">{grandTotal.toLocaleString()}</TableCell>
        {dynamicRow.map((payment, index) => {
          const payments = row.payments.filter(
            (x) => x.paymentType.type === payment.type
          );
          const total = payments.reduce(
            (total, currentData) => (total = total + currentData.amount),
            0
          );
          return (
            <TableCell key={index} align="right">
              {total.toLocaleString()}
            </TableCell>
          );
        })}
        <TableCell padding="checkbox">
          <ReactToPrint
            trigger={() => (
              <Tooltip title="Print">
                <IconButton>
                  <ReceiptIcon />
                </IconButton>
              </Tooltip>
            )}
            content={() => componentRef.current}
          />
          <div style={{ display: "none" }}>
            <POSSlip componentToPrint={componentRef} sales={row} />
          </div>
        </TableCell>
        {row.details.length > 0 ? (
          <TableCell>
            <IconButton size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        ) : (
          <TableCell></TableCell>
        )}
      </TableRow>
      {row.details.length > 0 && (
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={headCells.length + 3}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box
                marginLeft={5}
                marginTop={1}
                marginRight={5}
                marginBottom={2}
              >
                <Typography variant="h6" gutterBottom component="div">
                  Details
                </Typography>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox"></TableCell>
                      <TableCell align="center">Category</TableCell>
                      <TableCell align="center">Type</TableCell>
                      <TableCell>Item Code</TableCell>
                      <TableCell>Item Description</TableCell>
                      <TableCell>Medical Personnel</TableCell>
                      <TableCell>Nurse</TableCell>
                      <TableCell align="right">Price</TableCell>
                      <TableCell align="right">Disc</TableCell>
                      <TableCell align="right">Qty</TableCell>
                      <TableCell align="right">Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.details.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell align="center">
                          <Avatar
                            src={`${item.itemCategory.icon}`}
                            className={classes.large}
                          ></Avatar>
                        </TableCell>
                        <TableCell align="center">
                          {item.itemCategory.description}
                        </TableCell>
                        <TableCell align="center">
                          {item.itemType.description}
                        </TableCell>
                        <TableCell>{item.fullCode}</TableCell>
                        <TableCell>{item.itemDescription}</TableCell>
                        <TableCell>
                          {item.personnel?.name.toLocaleUpperCase()}
                        </TableCell>
                        <TableCell>
                          {item.nurse?.name.toLocaleUpperCase()}
                        </TableCell>
                        <TableCell align="right">
                          {item.price.toLocaleString()}
                        </TableCell>
                        <TableCell align="right">
                          {item.disc.toLocaleString()}
                        </TableCell>
                        <TableCell align="right">
                          {item.qty.toLocaleString()}
                        </TableCell>
                        <TableCell align="right">
                          {item.totalAmount.toLocaleString()}
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell align="right" colSpan={9}>
                        Disc Total:
                      </TableCell>
                      <TableCell align="center"></TableCell>
                      <TableCell align="right">
                        {row.disc.toLocaleString()}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="right" colSpan={9}>
                        Grand Total :
                      </TableCell>
                      <TableCell align="right">
                        {totalQty.toLocaleString()}
                      </TableCell>
                      <TableCell align="right">
                        {grandTotal.toLocaleString()}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
              <Box
                marginLeft={5}
                marginTop={1}
                marginRight={5}
                marginBottom={2}
              >
                <Typography variant="h6" gutterBottom component="div">
                  Payments
                </Typography>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox"></TableCell>
                      <TableCell align="center">Date</TableCell>
                      <TableCell align="center">Bank Name</TableCell>
                      <TableCell align="center">Account No.</TableCell>
                      <TableCell align="center">Owner</TableCell>
                      <TableCell align="right">Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.payments.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell>
                          <Chip
                            variant="outlined"
                            color="primary"
                            label={item.paymentType.type}
                          />
                        </TableCell>
                        <TableCell align="center">
                          {moment(row.date).format("DD-MMM-yyyy")}
                        </TableCell>
                        <TableCell align="center">
                          {item.bankAccount?.bankName}
                        </TableCell>
                        <TableCell align="center">
                          {item.bankAccount?.accountNo}
                        </TableCell>
                        <TableCell align="center">
                          {item.bankAccount?.owner}
                        </TableCell>
                        <TableCell align="right">
                          {item.amount.toLocaleString()}
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell align="right" colSpan={5}>
                        Grand Total :
                      </TableCell>
                      <TableCell align="right">
                        {totalPayment.toLocaleString()}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
});
