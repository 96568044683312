import { createStyles, Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";

export const usePosStyles = makeStyles((theme: Theme) =>
  createStyles({
    cart: {
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.up("xl")]: {
        height: "83vh",
      },
      height: "78vh",
      overflow: "auto",
      width: "100%",
      padding: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    items: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      padding: theme.spacing(2),
      marginBottom: theme.spacing(1),
      [theme.breakpoints.up("xl")]: {
        height: "75.5vh",
      },
      height: "68.5vh",
    },
    items_items: {
      display: "flex",
      flexDirection: "column",
      overflow: "auto",
      padding: theme.spacing(0, 1),
    },
    title: {
      backgroundColor: "#007bb2",
      padding: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    transaction: {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(2),
      marginBottom: theme.spacing(1),
      [theme.breakpoints.up("xl")]: {
        height: "37.8vh",
      },
      [theme.breakpoints.only("xs")]: {
        height: "auto",
      },
      height: "32.5vh",
      overflow: "auto",
      width: "100%",
    },
    total: {
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.up("xl")]: {
        height: "21vh",
      },
      [theme.breakpoints.only("xs")]: {
        height: "auto",
      },
      height: "26vh",
      overflow: "auto",
      width: "100%",
      padding: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    save: {
      [theme.breakpoints.up("xl")]: {
        height: "8vh",
      },
      fontSize: 20,
    },
    pay: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      [theme.breakpoints.up("xl")]: {
        height: "13.7vh",
      },
      height: "10vh",
      fontSize: 20,
    },
    card: {
      padding: theme.spacing(1, 2, 1, 2),
    },
    paging: {
      marginTop: theme.spacing(1),
    },
    grid: {
      marginBottom: theme.spacing(1),
    },
    slip: {
      width: "100%",
      padding: theme.spacing(4),
      color: "black",
    },
    table: {
      width: "100%",
    },
    page_break: {
      marginBottom: theme.spacing(2),
    },
    appBar: {
      top: "auto",
      bottom: 0,
    },
  })
);
