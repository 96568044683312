export interface StockBalance {
  itemCode: string;
  itemDescription: string;
  itemCategory: string;
  icon: string
  itemType: string;
  batch: string;
  factoryName: string;
  supplierName: string;
  expiredDate: Date;
  qty: number;
}

export class StockBalanceSearch {
  itemCode: string = "";
  date: Date = new Date();
  itemCategory: string = "";
  itemType: string = "";
  itemDescription: string = "";

  constructor(search?: StockBalanceSearch) {
    if (search) {
      this.itemCode = search.itemCode;
      this.date = search.date;
      this.itemCategory = search.itemCategory;
      this.itemType = search.itemType;
      this.itemDescription = search.itemDescription;
    }
  }
}
