import {
  Card,
  CardHeader,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  CardContent,
  Chip,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React from "react";
import { Link } from "react-router-dom";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useStyles } from "../../app/layout/style";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import { Nurse } from "../../app/models/nurses";

interface Props {
  person: Nurse;
  deletePerson: (id: string) => void;
}

export default observer(function NurseCard({
  person,
  deletePerson,
}: Props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDelete = (id: string) => {
    setAnchorEl(null);
    deletePerson(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card style={{ height: "100%" }}>
      <CardHeader
        avatar={
          <Avatar className={classes.avatar}>
            {person.name.substring(0, 1)}
          </Avatar>
        }
        action={
          <>
            <IconButton aria-label="settings" onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem component={Link} to={`/nurses/${person.id}`}>
                Update
              </MenuItem>
              <MenuItem onClick={() => handleDelete(person.id)}>
                Delete
              </MenuItem>
            </Menu>
          </>
        }
        title={person.name}
        subheader={`Fee: ${person.fee} %`}
      />
      <CardContent>
        {!person.isActive && (
          <Chip
            icon={<SentimentVeryDissatisfiedIcon />}
            variant="outlined"
            color="secondary"
            label="Deleted"
          />
        )}
      </CardContent>
    </Card>
  );
});
