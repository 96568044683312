import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Chip,
  IconButton,
  useTheme,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { StockLocation } from "../../app/models/location";
import EditIcon from "@material-ui/icons/Edit";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import { useStyles } from "../../app/layout/style";

interface Props {
  location: StockLocation;
}

export default observer(function StockLocationCard({ location }: Props) {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar className={classes.avatar}>
            {location.name.substring(5, 4)}
          </Avatar>
        }
        action={
          <IconButton component={Link} to={`/stocklocation/${location.id}`}>
            <EditIcon />
          </IconButton>
        }
        title={location.name}
        subheader={<SubHeaderCard location={location} />}
      />
      <CardContent className={classes.content}>
        <div className={classes.chip}>
          {!location.isActive && (
            <Chip
              icon={<SentimentVeryDissatisfiedIcon />}
              variant="outlined"
              color="secondary"
              label="Deleted"
            />
          )}
        </div>
      </CardContent>
    </Card>
  );
});

function SubHeaderCard({ location }: Props) {
  const theme = useTheme();
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          marginTop: theme.spacing(1),
        }}
      >
        <LocationCityIcon />
        <span style={{ marginLeft: "8px" }}>{location.city}</span>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          marginTop: theme.spacing(1),
        }}
      >
        <ContactPhoneIcon />
        <span style={{ marginLeft: "8px" }}>{location.phoneNo}</span>
      </div>
    </>
  );
}
