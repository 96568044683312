import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import {Skeleton} from "@material-ui/lab";
import {observer} from "mobx-react-lite";
import {Helmet} from "react-helmet";
import {useStore} from "../stores/store";

interface Props {
  menuName: string;
}

export default observer(function Title({menuName}: Props) {
  const {menuStore} = useStore();
  const {loadingMenu} = menuStore;

  return (
    <>
      {loadingMenu ? (
        <>
          <Typography variant="h4">
            <Skeleton width="50%" />
          </Typography>
          <Divider />
        </>
      ) : (
        <>
          <Helmet>
            <title>PERSADA MEDIC - {menuName}</title>
          </Helmet>
          <Typography variant="h4">{menuName}</Typography>
          <Divider />
        </>
      )}
    </>
  );
});
