import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { useRef } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PrintIcon from "@material-ui/icons/Print";
import { Link } from "react-router-dom";
import { Sale } from "../../app/models/sale";
import { useStyles } from "../../app/layout/style";
import moment from "moment";
import ReactToPrint from "react-to-print";
import POSSlip from "./POSSlip";
import { useStore } from "../../app/stores/store";

interface Props {
  sales: Sale;
  deleteSales: (id: string) => void;
}

export default observer(function InvoiceCard(props: Props) {
  const classes = useStyles();
  const { userStore, salesStore } = useStore();
  const { user } = userStore;
  const { getTotalQty, getSubTotal } = salesStore;
  const { sales, deleteSales } = props;
  const [expanded, setExpanded] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const componentRef = useRef(null);
  const totalQty = getTotalQty(sales.details);
  const subTotal = getSubTotal(sales.details);
  const grandTotal = subTotal - sales.disc;
  const role = user?.roles.find((x) => x.name === "Developer");
  const restricted = role
    ? false
    : new Date(sales.date).setHours(0, 0, 0, 0) <
      new Date().setHours(0, 0, 0, 0);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDelete = (id: string) => {
    setAnchorEl(null);
    deleteSales(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card>
      <CardHeader
        action={
          <>
            <IconButton aria-label="settings" onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                disabled={restricted}
                component={Link}
                to={`/invoice/${sales.id}?stat=${sales.status}`}
              >
                Update
              </MenuItem>
              <MenuItem
                disabled={restricted}
                onClick={() => handleDelete(sales.id)}
              >
                Delete
              </MenuItem>
            </Menu>
          </>
        }
        title={sales.documentNo}
        subheader={moment(sales.date).format("MMMM DD, YYYY")}
      />
      <CardContent>
        {sales.customer.customerName !== "" && (
          <Typography gutterBottom variant="body1" component="h2">
            CUSTOMER: {sales.customer.customerName.toUpperCase()}
          </Typography>
        )}
        {sales.payments.map((payment) => (
          <Grid key={payment.id} container justifyContent="space-between">
            <Grid item>
              <Typography gutterBottom variant="body1" component="h2">
                {payment.paymentType.type}
              </Typography>
            </Grid>
            <Grid item>
              <Typography gutterBottom variant="body1" component="h2">
                {payment.amount.toLocaleString()}
              </Typography>
            </Grid>
          </Grid>
        ))}
        <Grid container justifyContent="space-between">
          <Grid item></Grid>
          <Grid item>
            <Typography gutterBottom variant="subtitle1" component="h4">
              {`TOTAL : ${grandTotal.toLocaleString()}`}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions disableSpacing>
        <ReactToPrint
          trigger={() => (
            <Tooltip title="Print">
              <IconButton>
                <PrintIcon />
              </IconButton>
            </Tooltip>
          )}
          content={() => componentRef.current}
        />
        <div style={{ display: "none" }}>
          <POSSlip componentToPrint={componentRef} sales={sales} />
        </div>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {sales.details.map((row) => (
            <div key={row.id}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Avatar src={`${row.itemCategory.icon}`} />
                </Grid>
                <Grid item xs>
                  <Typography variant="body1">{row.itemDescription}</Typography>
                  <Typography variant="caption">{row.fullCode}</Typography>
                </Grid>
                <Grid item>
                  <Typography align="right" variant="body2" component="span">
                    x{row.qty}
                  </Typography>
                </Grid>
                <Grid item>
                  {row.disc > 0 && (
                    <Typography
                      align="right"
                      variant="caption"
                      color="secondary"
                      component="span"
                    >
                      {`${row.disc}% `}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <Typography
                    className={classes.price}
                    variant="body2"
                    component="span"
                    align="right"
                  >
                    IDR {row.price.toLocaleString("en-US")}
                  </Typography>
                </Grid>
              </Grid>
              <div className={classes.toolbar}></div>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>Personnel:</Grid>
                    <Grid item>
                      <Typography variant="body1" component="h2" align="center">
                        {row.personnel?.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>Nurse:</Grid>
                    <Grid item>
                      <Typography variant="body1" component="h2" align="center">
                        {row.nurse?.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography align="right" variant="body2" component="p">
                    Total: {row.totalAmount.toLocaleString("en-US")}
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
            </div>
          ))}
          <Typography align="right" variant="body2" component="p">
            Total Qty: {totalQty.toLocaleString("en-US")}
          </Typography>
          <Typography align="right" variant="body2" component="p">
            Disc Total: {sales.disc.toLocaleString("en-US")}
          </Typography>
          <Typography align="right" variant="body2" component="p">
            Grand Total: {grandTotal.toLocaleString("en-US")}
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
});
