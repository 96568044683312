import {
  Paper,
  Typography,
  Divider,
  Grid,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import InputField from "../../app/common/form/InputField";
import MySwitch from "../../app/common/form/MySwitch";
import * as Yup from "yup";
import { v4 as uuid } from "uuid";
import { QtyInputField } from "../../app/common/form/NumberInputField";
import { useStyles } from "../../app/layout/style";
import {
  MedicalPersonnel,
  MedicalPersonnelFormValues,
} from "../../app/models/medicalPersonnel";
import { useStore } from "../../app/stores/store";

export default observer(function MedicalPersonnelForm() {
  const classes = useStyles();
  const { medicalPersonnelStore, snackbarStore } = useStore();
  const { createPerson, updatePerson, loadPerson, loadingPerson } =
    medicalPersonnelStore;
  const { openSnackbar } = snackbarStore;
  const [person, setPerson] = React.useState<MedicalPersonnelFormValues>(
    new MedicalPersonnelFormValues()
  );
  const history = useHistory();
  let { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (id) {
      loadPerson(id).then((person) => {
        loadPersonResult(person);
      });
    }
  }, [id, loadPerson]);

  const loadPersonResult = (person: MedicalPersonnel | undefined) => {
    if (person) {
      const value = new MedicalPersonnelFormValues({
        id: person.id,
        name: person.name,
        fee: person.fee,
        isActive: person.isActive,
      });
      setPerson(value);
    }
  };

  const schema = Yup.object({
    name: Yup.string().required("Person Name is required."),
    fee: Yup.number()
      .required("Item Code is required.")
      .typeError("Quantity is must be a number."),
  });

  const handleFormSubmit = (
    person: MedicalPersonnelFormValues,
    resetForm: () => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    if (!person.id) {
      const newId = uuid();
      let newPerson = {
        ...person,
        id: newId,
      };
      createPerson(newPerson)
        .catch((error) => {
          openSnackbar(error, "error");
          setSubmitting(false);
        })
        .then((message) => {
          if (message !== undefined) {
            openSnackbar(message, "success");
            setPerson(new MedicalPersonnelFormValues());
            resetForm();
          }
        });
    } else {
      updatePerson(person)
        .catch((error) => {
          openSnackbar(error, "error");
          setSubmitting(false);
        })
        .then((message) => {
          if (message !== undefined) {
            openSnackbar(message, "success");
            history.push("/medicalpersonnels");
          }
        });
    }
  };

  return (
    <Paper className={classes.form}>
      <Typography variant="h5">Form {id ? "Update" : "Create"}</Typography>
      <Divider />
      <Formik
        validationSchema={schema}
        enableReinitialize
        initialValues={person}
        onSubmit={(values, { resetForm, setSubmitting }) =>
          handleFormSubmit(values, resetForm, setSubmitting)
        }
      >
        {({ handleSubmit, isSubmitting, isValid }) => (
          <Form onSubmit={handleSubmit} autoComplete="off">
            <Grid container>
              <Grid item xs={12} lg={6}>
                <InputField
                  variant="standard"
                  label="Person Name"
                  name="name"
                  placeholder="Please input customer name"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={isSubmitting || loadingPerson}
                />
                <QtyInputField
                  variant="standard"
                  label="Fee"
                  placeholder="Please input fee person"
                  name="fee"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  andorment="%"
                  margin="normal"
                  disabled={isSubmitting || loadingPerson}
                />
                {id && (
                  <MySwitch
                    name="isActive"
                    label="Active Person"
                    disabled={isSubmitting || loadingPerson}
                    checked
                  />
                )}
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="flex-end"
              alignItems="stretch"
              spacing={1}
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting || !isValid}
                >
                  {isSubmitting && (
                    <CircularProgress
                      className={classes.progress}
                      size={16}
                      color="inherit"
                    />
                  )}
                  Save
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="default"
                  component={Link}
                  to="/medicalpersonnels"
                  disabled={isSubmitting || loadingPerson}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Paper>
  );
});
