import { observer } from "mobx-react-lite";
import React from "react";
import { Switch, useLocation, useRouteMatch } from "react-router-dom";
import PrivateRouteWithMenu from "../../app/layout/PrivateRouteWithMenu";
import InvoiceList from "./InvoiceList";
import POS from "./POS";
import POSGeneral from "./POSGeneral";

export default observer(function Invoice() {
  let { path } = useRouteMatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get("stat");

  return (
    <>
      <Switch>
        <PrivateRouteWithMenu
          exact
          path={path}
          component={InvoiceList}
          link={path}
        />
        <PrivateRouteWithMenu
          path={`${path}/:id`}
          component={query === "1" ? POS : POSGeneral}
          link={path}
        />
      </Switch>
    </>
  );
});
