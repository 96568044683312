import { ItemDocument } from "./item";

export interface MedicalPersonnel {
  id: string;
  name: string;
  fee: number;
  isActive: boolean;
  details: PersonnelFee[];
}

export interface PersonnelFee extends ItemDocument {
  date: Date;
  documentNo: string;
  customer: string;
  fee: number;
  feeAmount: number;
  totalAmount: number;
}

export class MedicalPersonnelSearch {
  startDate: Date = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
  );
  endDate: Date = new Date();

  constructor(search?: MedicalPersonnelSearch) {
    if (search) {
      this.startDate = search.startDate;
      this.endDate = search.endDate;
    }
  }
}

export class MedicalPersonnelFormValues {
  id?: string = "";
  name: string = "";
  fee: number = Number.NaN;
  isActive: boolean = true;

  constructor(person?: MedicalPersonnelFormValues) {
    if (person) {
      this.id = person.id;
      this.name = person.name;
      this.fee = person.fee;
      this.isActive = person.isActive;
    }
  }
}
