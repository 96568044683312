import { Typography, Divider, Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useStore } from "../../app/stores/store";
import ItemCardSkeleton from "../Items/ItemCardSkeleton";
import { useStyles } from "../../app/layout/style";
import NurseFeeFilter from "./NurseFeeFilter";
import NurseFeeCard from "./NurseFeeCard";

export default observer(function NurseFee() {
  const classes = useStyles();
  const { menuStore, nurseStore } = useStore();
  const { loadingMenu } = menuStore;
  const { personnelsFee, loadNurseFee, loadingPerson } =
  nurseStore;
  const skeletonCardArray = Array(6).fill("");

  useEffect(() => {
    loadNurseFee();
  }, [loadNurseFee]);

  return (
    <>
      {loadingMenu ? (
        <>
          <Typography variant="h4">
            <Skeleton width="50%" />
          </Typography>
          <Divider />
        </>
      ) : (
        <>
          <Helmet>
            <title>PERSADA MEDIC - Nurse Fee</title>
          </Helmet>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h4">Nurse Fee</Typography>
            </Grid>
            <Grid item>
              <NurseFeeFilter />
            </Grid>
          </Grid>
          <Divider />
          <Grid container spacing={1} className={classes.transferList}>
            {loadingPerson
              ? skeletonCardArray.map((person, index) => (
                  <Grid key={index} item xs={12} md={4}>
                    <ItemCardSkeleton />
                  </Grid>
                ))
              : personnelsFee.map((person) => (
                  <Grid item xs={12} md={4} key={person.id}>
                    <NurseFeeCard person={person} />
                  </Grid>
                ))}
          </Grid>
        </>
      )}
    </>
  );
});
