import MenuItem from "@material-ui/core/MenuItem";
import { useField } from "formik";
import React from "react";
import { DropDownProps } from "../../models/dropDownProps";
import { BaseTextFieldProps, TextField } from "@material-ui/core";

interface Props extends BaseTextFieldProps {
  placeholder?: string;
  name: string;
  options: DropDownProps[] | undefined;
  onChange?: (e: React.ChangeEvent<{ value: unknown }>) => void;
  titleDefaultSelected?: string;
  displayDefaultSelected?: boolean;
  displayErrorMessage?: boolean;
}

export default function Dropdown2(props: Props) {
  const [field, meta, helpers] = useField(props.name);
  const {
    titleDefaultSelected,
    displayDefaultSelected,
    displayErrorMessage,
    ...other
  } = props;

  const handleChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    helpers.setValue(e.target.value);
    if (props.onChange !== undefined) {
      props.onChange(e);
    }
  };

  return (
    <>
      <TextField
        {...other}
        id={props.name}
        value={field.value}
        select
        onChange={(e) => handleChange(e)}
        error={meta.touched && !!meta.error}
        helperText={meta.touched && meta.error && !displayErrorMessage}
        fullWidth
        margin={props.margin ? props.margin : "normal"}
        color="primary"
      >
        {displayDefaultSelected && (
          <MenuItem value="All">
            {titleDefaultSelected ? titleDefaultSelected : "Not Selected"}
          </MenuItem>
        )}
        {props.options !== undefined &&
          props.options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.text}
            </MenuItem>
          ))}
      </TextField>
    </>
  );
}
