import { Avatar } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import {
  StyledTableRow,
  StyledTableCell,
} from "../../app/common/table/EnhancedTableHead";
import { StockBalance } from "../../app/models/inventories";
import moment from "moment";

interface Props {
  row: StockBalance;
}

export default observer(function ReportStockBalanceTableRow(props: Props) {
  const { row } = props;

  return (
    <StyledTableRow>
      <StyledTableCell component="th" scope="row" align="center">
        <Avatar src={`${row.icon}`}></Avatar>
      </StyledTableCell>
      <StyledTableCell>{row.itemCategory}</StyledTableCell>
      <StyledTableCell>{row.itemType}</StyledTableCell>
      <StyledTableCell>{row.itemCode}</StyledTableCell>
      <StyledTableCell>{row.itemDescription}</StyledTableCell>
      <StyledTableCell>{row.batch}</StyledTableCell>
      <StyledTableCell>{row.supplierName}</StyledTableCell>
      <StyledTableCell>{row.factoryName}</StyledTableCell>
      <StyledTableCell>{moment(row.expiredDate).format("MMM-yyyy")}</StyledTableCell>
      <StyledTableCell align="right">{row.qty}</StyledTableCell>
    </StyledTableRow>
  );
});
