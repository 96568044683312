import {
  Card,
  CardContent,
  Typography,
  CardHeader,
  Grid,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

export default function ItemCardSkeleton() {
  return (
    <Card>
      <CardHeader
        avatar={
          <Skeleton animation="wave" variant="circle" width={40} height={40} />
        }
        title={<Skeleton animation="wave" width="80%" />}
        subheader={<Skeleton animation="wave" width="40%" />}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          <Skeleton width="50%" />
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Typography variant="body2" component="p">
              <Skeleton width={100} />
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" component="p">
              <Skeleton width={100} />
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
