import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import { Customer, CustomerFormValues } from "../models/customer";
import { PaginatedResult, Pagination } from "../models/pagination";
import { Order, SortBy } from "../models/table";

export default class CustomerStore {
  customerRegistry = new Map<string, Customer>();
  loadingCustomer = false;
  predicate = new Map<string, string>();
  rowsOptions = [6];
  pagination: Pagination | null = null;
  sortBy: SortBy<Customer>[] = [
    { id: "customerName", label: "Customer Name" },
    { id: "phoneNo", label: "Phone Number" },
    { id: "address", label: "Address" },
  ];
  orderBy: Order = "asc";

  constructor() {
    makeAutoObservable(this);
    this.predicate.set("pageNumber", "1");
    this.predicate.set("orderBy", this.orderBy);

    reaction(
      () => this.predicate.keys(),
      () => {
        this.loadCustomers();
      }
    );
  }

  setPredicate = (value: string) => {
    this.predicate.delete("search");
    this.predicate.set("search", value);
    this.predicate.delete("pageNumber");
    this.predicate.set("pageNumber", "1");
  };

  setPageNumber = (value: number) => {
    this.predicate.delete("pageNumber");
    this.predicate.set("pageNumber", value.toString());
  };

  setPageSize = (value: number) => {
    this.predicate.delete("pageSize");
    this.predicate.set("pageSize", value.toString());
    // this.predicate.set("pageNumber", "1");
  };

  setSortBy = (value: Extract<keyof Customer, string> | null) => {
    this.predicate.delete("sortBy");
    if (value !== null) this.predicate.set("sortBy", value.toString());
  };

  setOrderBy = (value: Order) => {
    this.predicate.delete("orderBy");
    this.predicate.set("orderBy", value.toString());
    this.orderBy = value;
  };

  setRowsOptions = (value: number[]) => {
    this.rowsOptions = value;
  };

  get axiosParams() {
    const params = new URLSearchParams();
    this.predicate.forEach((value, key) => {
      params.delete(key);
      params.append(key, value);
    });
    return params;
  }

  get pageNumber() {
    return Number(this.predicate.get("pageNumber")) - 1;
  }

  get pageSize() {
    return Number(this.predicate.get("pageSize"));
  }

  loadCustomerProcess = (result: PaginatedResult<Customer[]>) => {
    runInAction(() => {
      this.customerRegistry.clear();
      result.data.forEach((item) => {
        this.customerRegistry.set(item.id, item);
      });
      this.pagination = result.pagination;
    });
  };

  loadCustomers = async () => {
    this.loadingCustomer = true;

    try {
      const result = await agent.Customers.list(this.axiosParams);
      this.loadCustomerProcess(result);
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingCustomer = false));
    }
  };

  get customerList() {
    return Array.from(this.customerRegistry);
  }

  loadCustomer = async (id: string) => {
    this.loadingCustomer = true;

    try {
      const result = await agent.Customers.details(id);
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingCustomer = false));
    }
  };

  createCustomer = async (customer: CustomerFormValues) => {
    try {
      await agent.Customers.create(customer);
      const result = await agent.Customers.list(this.axiosParams);
      this.loadCustomerProcess(result);
      return "Create customer success!";
    } catch (error) {
      throw error;
    }
  };

  updateCustomer = async (customer: CustomerFormValues) => {
    try {
      const result = await agent.Customers.update(customer);
      runInAction(() => {
        this.customerRegistry.set(result.id, result);
      });

      return "Update customer success!";
    } catch (error) {
      throw error;
    }
  };

  deleteCustomer = async (id: string) => {
    this.loadingCustomer = true;

    try {
      await agent.Customers.delete(id);
      const result = await agent.Customers.list(this.axiosParams);
      this.loadCustomerProcess(result);
      return "Delete customer success!";
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingCustomer = false));
    }
  };
}
