import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import {
  Item,
  ItemCategory,
  ItemDropdown,
  ItemFormValues,
  ItemType,
} from "../models/item";
import { PaginatedResult, Pagination } from "../models/pagination";
import { Order, SortBy } from "../models/table";

export default class ItemStore {
  itemsRegistry = new Map<string, Item>();
  itemsDropDown: ItemDropdown[] = [];
  itemsDropDownOnlyForMedicalDoctor: ItemDropdown[] = [];
  itemCategories: ItemCategory[] = [];
  itemTypes: ItemType[] = [];
  loadingItem = false;
  predicate = new Map<string, string>();
  rowsOptions = [6];
  pagination: Pagination | null = null;
  sortBy: SortBy<Item>[] = [
    { id: "itemCategory", label: "Item Category" },
    { id: "itemType", label: "Item Type" },
    { id: "fullCode", label: "Item Code" },
    { id: "itemPrice", label: "Price" },
  ];
  orderBy: Order = "asc";

  constructor() {
    makeAutoObservable(this);
    this.predicate.set("pageNumber", "1");
    this.predicate.set("orderBy", this.orderBy);

    reaction(
      () => this.predicate.keys(),
      () => {
        this.loadItems();
      }
    );
  }

  setPredicate = (value: string) => {
    this.predicate.delete("search");
    this.predicate.set("search", value);
    this.predicate.delete("pageNumber");
    this.predicate.set("pageNumber", "1");
  };

  setPageNumber = (value: number) => {
    this.predicate.delete("pageNumber");
    this.predicate.set("pageNumber", value.toString());
  };

  setPageSize = (value: number) => {
    this.predicate.delete("pageSize");
    this.predicate.set("pageSize", value.toString());
    // this.predicate.set("pageNumber", "1");
  };

  setSortBy = (value: Extract<keyof Item, string> | null) => {
    this.predicate.delete("sortBy");
    if (value !== null) this.predicate.set("sortBy", value.toString());
  };

  setOrderBy = (value: Order) => {
    this.predicate.delete("orderBy");
    this.predicate.set("orderBy", value.toString());
    this.orderBy = value;
  };

  setRowsOptions = (value: number[]) => {
    this.rowsOptions = value;
  };

  get axiosParams() {
    const params = new URLSearchParams();
    this.predicate.forEach((value, key) => {
      params.delete(key);
      params.append(key, value);
    });
    return params;
  }

  get pageNumber() {
    return Number(this.predicate.get("pageNumber")) - 1;
  }

  get pageSize() {
    return Number(this.predicate.get("pageSize"));
  }

  loadItemProccess = (result: PaginatedResult<Item[]>) => {
    runInAction(() => {
      this.itemsRegistry.clear();
      result.data.forEach((item) => {
        this.itemsRegistry.set(item.id, item);
      });
      this.pagination = result.pagination;
    });
  };

  loadItems = async () => {
    this.loadingItem = true;

    try {
      const result = await agent.Items.list(this.axiosParams);
      this.loadItemProccess(result);
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingItem = false));
    }
  };

  get itemList() {
    return Array.from(this.itemsRegistry);
  }

  loadItem = async (id: string) => {
    this.loadingItem = true;

    try {
      const result = await agent.Items.details(id);
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingItem = false));
    }
  };

  loadItemTypes = async () => {
    this.loadingItem = true;

    try {
      const result = await agent.Items.loadItemTypes();
      runInAction(() => {
        this.itemTypes = result;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingItem = false));
    }
  };

  loadCategories = async () => {
    this.loadingItem = true;

    try {
      const result = await agent.Items.loadCategories();
      runInAction(() => {
        this.itemCategories = result;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingItem = false));
    }
  };

  loadDropDownItem = async () => {
    this.loadingItem = true;

    try {
      const dropdown = await agent.Items.dropdown();
      runInAction(() => {
        this.itemsDropDown = dropdown;
      });
      return dropdown;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingItem = false));
    }
  };

  loadItemOnlyForMedicalDoctor = async () => {
    this.loadingItem = true;

    try {
      const dropdown = await agent.Items.itemonlyformedicaldoctor();
      runInAction(() => {
        this.itemsDropDownOnlyForMedicalDoctor = dropdown;
      });
      return dropdown;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingItem = false));
    }
  };

  generateCode = async (itemTypeId?: string) => {
    this.loadingItem = true;

    try {
      if (itemTypeId) {
        return await agent.Items.getItemCode(itemTypeId);
      } else {
        return "";
      }
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingItem = false));
    }
  };

  createItem = async (item: ItemFormValues) => {
    try {
      await agent.Items.create(item);
      const result = await agent.Items.list(this.axiosParams);
      this.loadItemProccess(result);
      return "Create item success!";
    } catch (error) {
      throw error;
    }
  };

  updateItem = async (item: ItemFormValues) => {
    try {
      const resultUpdate = await agent.Items.update(item);
      runInAction(() => {
        this.itemsRegistry.set(resultUpdate.id, resultUpdate);
      });

      return "Update item success!";
    } catch (error) {
      throw error;
    }
  };

  deleteItem = async (id: string) => {
    this.loadingItem = true;

    try {
      await agent.Items.delete(id);
      const result = await agent.Items.list(this.axiosParams);
      this.loadItemProccess(result);
      return "Delete item success!";
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingItem = false));
    }
  };

  searchItem = (search: string) => {
    if (search !== "")
      return this.itemsDropDown.filter((x) =>
        x.itemDescription.toLowerCase().includes(search.toLowerCase())
      );

    return this.itemsDropDown;
  };

  searchItemOnlyForGeneralMedical = (search: string) => {
    if (search !== "")
      return this.itemsDropDownOnlyForMedicalDoctor.filter((x) =>
        x.itemDescription.toLowerCase().includes(search.toLowerCase())
      );

    return this.itemsDropDown;
  };
}
