import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useStyles } from "../../app/layout/style";
import ReactToPrint from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
import { useRef } from "react";
import NurseFeeSlip from "./NurseFeeSlip";
import { Nurse } from "../../app/models/nurses";

interface Props {
  person: Nurse;
}

export default observer(function NurseFeeCard({ person }: Props) {
  const classes = useStyles();
  const componentRef = useRef(null);
  const totalFee = person.details.reduce(
    (total, currentData) => (total = total + currentData.feeAmount),
    0
  );

  return (
    <Card style={{ height: "100%" }}>
      <CardHeader
        avatar={
          <Avatar className={classes.avatar}>
            {person.name.substring(0, 1)}
          </Avatar>
        }
        title={person.name}
        subheader={`Fee: ${person.fee} %`}
      />
      <CardContent>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <Typography variant="body1">
              Total: IDR {totalFee.toLocaleString("EN-US")}
            </Typography>
          </Grid>
          <Grid item>
            {totalFee > 0 && (
              <>
                <ReactToPrint
                  trigger={() => (
                    <Tooltip title="Print Details">
                      <IconButton>
                        <PrintIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  content={() => componentRef.current}
                />
                <div style={{ display: "none" }}>
                  <NurseFeeSlip
                    componentToPrint={componentRef}
                    person={person}
                  />
                </div>
              </>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
});
