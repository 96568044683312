import {
  DialogTitle,
  DialogContent,
  Button,
  CircularProgress,
  DialogActions,
  Grid,
  IconButton,
  Tooltip,
  Paper,
  Chip,
  Dialog,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { ChangeEvent } from "react";
import { useStyles } from "../../app/layout/style";
import {
  PaymentType,
  SalesDetail,
  SalesFormValues,
  SalesPayment,
  SalesPaymentValues,
} from "../../app/models/sale";
import { useStore } from "../../app/stores/store";
import PaymentTypeAutoComplete from "./PaymentTypeAutoComplete";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { v4 as uuid } from "uuid";
import Dropdown from "../../app/common/form/Dropdown";
import PriceInputField from "../../app/common/form/NumberInputField";
import { Form } from "formik";
import DateInputField from "../../app/common/form/DateInputFIeld";

interface Props {
  sales: SalesFormValues;
  details: SalesDetail[];
  setSales: (sales: SalesFormValues) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  payments: SalesPayment[];
  setPayments: (payments: SalesPayment[]) => void;
  paymentGroup: PaymentType[];
  setPaymentGroup: (paymentGroup: PaymentType[]) => void;
  isSubmitting: boolean;
  submitForm: () => void;
  setStatus: (status: "save" | "pay" | undefined) => void;
}

export default observer(function POSPayment({
  sales,
  details,
  setSales,
  open,
  setOpen,
  payments,
  setPayments,
  paymentGroup,
  setPaymentGroup,
  isSubmitting,
  submitForm,
  setStatus,
}: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { salesStore, userStore } = useStore();
  const { paymentTypes, bankAccounts, getSubTotal, getTotalPayment } =
    salesStore;
  const { user } = userStore;
  const [value, setValue] = React.useState<PaymentType | null>(null);
  const subTotal = getSubTotal(details);
  const totalAmount = subTotal - sales?.disc;
  const changeAmount = getTotalPayment(payments);
  const grandTotal = React.useMemo(
    () => totalAmount - changeAmount,
    [changeAmount, totalAmount]
  );
  const change = React.useMemo(
    () => changeAmount - totalAmount,
    [changeAmount, totalAmount]
  );
  const role = React.useMemo(
    () => user?.roles.find((x) => x.name === "Developer"),
    [user?.roles]
  );

  const handleCancel = () => {
    setOpen(false);
    setStatus(undefined);
    if (!sales.id) {
      setPayments([]);
      setSales({ ...sales, payments: [] });
    }
  };

  const handleFormPay = () => {
    setStatus("pay");
    if (change > 0) {
      const id = uuid();
      const value = paymentTypes.find((x) => !x.display);
      if (value) {
        setSales(
          new SalesFormValues({
            ...sales,
            payments: sales.payments.concat(
              new SalesPaymentValues({
                id: id,
                salesId: sales.id,
                paymentTypeId: value.id,
                needAccountNo: value.needAccountNo,
                bankAccountId: value.bankAccountId,
                date: sales.date,
                amount: -change,
              })
            ),
          })
        );
      }
    }
    submitForm();
  };

  const handleAddItems = () => {
    if (value) {
      const id = uuid();
      const bankAccountId =
        value.bankAccountId === null ? bankAccounts[0].id : value.bankAccountId;
      setPayments(
        payments.concat({
          id: id,
          salesId: sales.id,
          paymentType: value,
          date: new Date(),
          amount: 0,
        } as SalesPayment)
      );
      setSales(
        new SalesFormValues({
          ...sales,
          payments: sales.payments.concat(
            new SalesPaymentValues({
              id: id,
              salesId: sales.id,
              paymentTypeId: value.id,
              needAccountNo: value.needAccountNo,
              bankAccountId: bankAccountId,
              date: sales.date,
            })
          ),
        })
      );
      setPaymentGroup(paymentGroup.concat(value));
    }
    setValue(null);
  };

  const handleDeleteItem = (payment: SalesPayment) => {
    setPayments(payments.filter((x) => x.id !== payment.id));
    setSales(
      new SalesFormValues({
        ...sales,
        payments: sales.payments.filter((x) => x.id !== payment.id),
      })
    );
    setPaymentGroup(
      paymentGroup.filter((x) => x.id !== payment.paymentType.id)
    );
  };

  const handleAmountChange = (
    e: ChangeEvent<{
      value: unknown;
    }>,
    index: number
  ) => {
    const amount = Number(e.target.value);
    let items = [...payments];
    let item = { ...payments[index] };

    item.amount = amount;
    items[index] = item;
    setPayments(items);
  };

  const closeDialog = (
    event: {},
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (!reason) setOpen(false);
    setPayments([]);
    setSales({ ...sales, payments: [] });
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      maxWidth="md"
      fullWidth
      fullScreen={fullScreen}
    >
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid item xs>
            Payment
          </Grid>
          <Grid item>
            <div className={classes.chip_column}>
              <Chip
                variant="outlined"
                color="primary"
                label={`Total Amount: ${totalAmount.toLocaleString()}`}
              />
              <Chip
                variant="outlined"
                color="primary"
                label={`Payment Amount: ${changeAmount.toLocaleString()}`}
              />
              {change > 0 && (
                <Chip
                  variant="outlined"
                  color="secondary"
                  label={`Change: ${change.toLocaleString()}`}
                />
              )}
            </div>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Form autoComplete="off">
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid item lg={6} xs={12}>
              <Typography variant="h6" component="p">
                Add Payment Type
              </Typography>
            </Grid>
            <Grid item lg={6} xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs>
                  <PaymentTypeAutoComplete
                    options={paymentTypes.filter((x) => x.display)}
                    value={value}
                    setValue={setValue}
                    loading={isSubmitting}
                    optionDisabled={paymentGroup}
                  />
                </Grid>
                <Grid item>
                  <Tooltip title="Add Items">
                    <IconButton size="small" onClick={handleAddItems}>
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {payments.map((payment, index) => (
            <Paper key={index} className={classes.itemCard} variant="outlined">
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <Grid item lg={5} md={12} xs={12}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>
                      <Chip
                        variant="outlined"
                        color="primary"
                        label={payment.paymentType.type}
                      />
                    </Grid>
                    <Grid item>
                      <DateInputField
                        label="Payment Date"
                        inputVariant="standard"
                        name={`payments[${index}].date`}
                        disabled={isSubmitting || role === undefined}
                        maxDate={new Date()}
                        margin="dense"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={4} md={12} xs={12}>
                  <Dropdown
                    options={bankAccounts.map((x) => {
                      return {
                        text: `${x.owner} - ${x.bankName} - ${x.accountNo}`,
                        value: x.id,
                      };
                    })}
                    name={`payments[${index}].bankAccountId`}
                    placeholder="Bank Account"
                    label="Bank Account"
                    disabled={
                      isSubmitting || !payment.paymentType.needAccountNo
                    }
                    margin="dense"
                    displayDefaultSelected
                  />
                </Grid>
                <Grid item lg={3} md={12} xs={12}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item xs>
                      <PriceInputField
                        variant="standard"
                        label="Amount"
                        name={`payments[${index}].amount`}
                        margin="dense"
                        disabled={isSubmitting}
                        onChange={(e) => handleAmountChange(e, index)}
                        autoFocus
                      />
                    </Grid>
                    <Grid item>
                      <Tooltip title="Delete Item">
                        <IconButton
                          component="div"
                          disabled={isSubmitting}
                          size="small"
                          onClick={() => handleDeleteItem(payment)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          ))}
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCancel()} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => handleFormPay()}
          color="primary"
          disabled={isSubmitting || payments.length === 0 || grandTotal > 0}
        >
          {isSubmitting && (
            <CircularProgress
              className={classes.progress}
              size={16}
              color="inherit"
            />
          )}
          Pay
        </Button>
      </DialogActions>
    </Dialog>
  );
});
