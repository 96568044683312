import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import { StockBalance, StockBalanceSearch } from "../models/inventories";
import { Order, SortBy } from "../models/table";

export default class InventoriesStore {
  stockBalance: StockBalance[] = [];
  loadingStockBalance = false;
  predicate = new Map<string, string>();
  sortBy: SortBy<StockBalance>[] = [
    { id: "itemType", label: "Type" },
    { id: "itemCode", label: "Item Code" },
    { id: "itemCategory", label: "Item Category" },
    { id: "itemDescription", label: "Description" },
  ];
  orderBy: Order = "asc";

  constructor() {
    makeAutoObservable(this);
    this.predicate.set("date", new Date().toLocaleDateString("EN-US"));
    this.predicate.set("search", "");
    this.predicate.set("orderBy", this.orderBy);

    reaction(
      () => this.predicate.keys(),
      () => {
        this.loadStockBalance();
      }
    );
  }

  setPredicate = (search: StockBalanceSearch) => {
    Object.keys(search).forEach((key) => {
      this.predicate.delete(key);
    });
    this.predicate.set("date", new Date().toLocaleDateString("EN-US"));
    this.predicate.set("itemCode", search.itemCode);
    this.predicate.set("itemCategory", search.itemCategory);
    this.predicate.set("itemType", search.itemType);
    this.predicate.set("itemDescription", search.itemDescription);
  };

  setSortBy = (value: Extract<keyof StockBalance, string> | null) => {
    this.predicate.delete("sortBy");
    if (value !== null) this.predicate.set("sortBy", value.toString());
  };

  setOrderBy = (value: Order) => {
    this.predicate.delete("orderBy");
    this.predicate.set("orderBy", value.toString());
    this.orderBy = value;
  };

  get axiosParams() {
    const params = new URLSearchParams();
    this.predicate.forEach((value, key) => {
      params.delete(key);
      params.append(key, value);
    });

    return params;
  }

  loadStockBalance = async () => {
    this.loadingStockBalance = true;

    try {
      const result = await agent.Inventories.stockBalance(this.axiosParams);
      runInAction(() => {
        this.stockBalance = result;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingStockBalance = false));
    }
  };
}
