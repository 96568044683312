import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { Typography } from "@material-ui/core";
import { Nurse } from "../../app/models/nurses";

interface Props {
  name: string;
  options: Nurse[] | undefined;
  value: Nurse | null;
  setValue: (value: Nurse | null) => void;
  optionDisabled?: Nurse[];
  loading: boolean;
  onChange: (
    value: Nurse | null,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  touched: boolean | undefined;
  setFieldTouched: (
    field: string,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined
  ) => void;
  error: string | undefined;
}

export default function NurseAutoComplete({
  name,
  options,
  value,
  setValue,
  optionDisabled,
  loading,
  onChange,
  setFieldValue,
  error,
  touched,
  setFieldTouched,
}: Props) {
  const handleOptionDisabled = (item: Nurse) => {
    if (optionDisabled) return optionDisabled.some((x) => x.id === item.id);
    else return false;
  };

  const handleChange = (value: Nurse | null) => {
    setValue(value);
    onChange(value, setFieldValue);
  };

  return (
    <Autocomplete
      options={options === undefined ? [] : options}
      getOptionLabel={(option) => option.name.toLocaleUpperCase()}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionDisabled={(option) => handleOptionDisabled(option)}
      value={value}
      onChange={(event, newValue) => {
        if (typeof newValue !== "string") {
          handleChange(newValue);
        }
      }}
      renderOption={(option) => (
        <Typography variant="subtitle1" component="p" noWrap>
          {option.name}
        </Typography>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Nurse"
          placeholder="Please select nurse."
          margin="dense"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          error={touched && !!error}
          onBlur={() => {
            setFieldTouched(name, true);
          }}
        />
      )}
      disabled={loading}
    />
  );
}
