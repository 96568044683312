import {
  Grid,
  Avatar,
  Typography,
  CardActionArea,
  Card,
  TextField,
  InputAdornment,
  CircularProgress,
  IconButton,
  useTheme,
  useMediaQuery,
  Paper,
  TablePagination,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { v4 as uuid } from "uuid";
import { useStyles } from "../../app/layout/style";
import { usePosStyles } from "../../app/layout/pos";
import {
  SalesDetail,
  SalesDetailValues,
  SalesFormValues,
  SalesMedicalPersonnelValues,
} from "../../app/models/sale";
import { ItemDropdown } from "../../app/models/item";
import SearchIcon from "@material-ui/icons/Search";
import React, { useEffect } from "react";
import { useStore } from "../../app/stores/store";
import ClearIcon from "@material-ui/icons/Clear";
import POSItemDialog from "./POSItemDialog";

interface Props {
  sales: SalesFormValues;
  setSales: (sale: SalesFormValues) => void;
  details: SalesDetail[];
  setDetails: (details: SalesDetail[]) => void;
  items: ItemDropdown[] | undefined;
  setItems: (items: ItemDropdown[]) => void;
  search: string;
  setSearch: (search: string) => void;
  isSubmitting: boolean;
  loading: boolean;
}

export default observer(function POSItems({
  sales,
  setSales,
  details,
  setDetails,
  items,
  setItems,
  search,
  setSearch,
  isSubmitting,
  loading,
}: Props) {
  const classes = useStyles();
  const local = usePosStyles();
  const theme = useTheme();
  const widthSizeMatch = useMediaQuery(theme.breakpoints.up("xl"));
  const { itemStore, dialogStore } = useStore();
  const { itemsDropDown, searchItem } = itemStore;
  const { openDialog } = dialogStore;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  useEffect(() => {
    if (widthSizeMatch) {
      setPage(0);
      setRowsPerPage(10);
    } else {
      setPage(0);
      setRowsPerPage(5);
    }
  }, [widthSizeMatch]);

  const handleClick = (item: ItemDropdown) => {
    openDialog(
      <POSItemDialog item={item} handleConfirm={handleConfirm} />,
      "sm",
      undefined,
      true
    );
  };

  const handleConfirm = (
    item: ItemDropdown,
    value?: SalesMedicalPersonnelValues
  ) => {
    const id = uuid();
    const detail = details.find((x) => x.itemId === item.id);
    if (detail) {
      const filterDetails = details.filter((x) => x.itemId !== item.id);
      setDetails(
        filterDetails.concat(new SalesDetail(id, 1, value, sales.id, item))
      );
      const filterSalesDetails = sales.details.filter(
        (x) => x.itemId !== item.id
      );
      setSales(
        new SalesFormValues({
          ...sales,
          details: filterSalesDetails.concat(
            new SalesDetailValues({
              id: id,
              salesId: sales.id,
              itemId: item.id,
              personnelId: item.itemCategory.haveStock
                ? undefined
                : value?.medicalPersonnel?.id,
              price: item.itemPrice,
              disc: item.itemDisc,
              qty: 1,
              fee: value?.fee === undefined ? 0 : value?.fee,
            })
          ),
        })
      );
    } else {
      setDetails(
        details.concat(
          new SalesDetail(
            id,
            1,
            item.itemCategory.haveStock ? undefined : value,
            sales.id,
            item
          )
        )
      );
      setSales(
        new SalesFormValues({
          ...sales,
          details: sales.details.concat(
            new SalesDetailValues({
              id: id,
              salesId: sales.id,
              itemId: item.id,
              personnelId: item.itemCategory.haveStock
                ? undefined
                : value?.medicalPersonnel?.id,
              price: item.itemPrice,
              disc: item.itemDisc,
              qty: 1,
              fee: value?.fee === undefined ? 0 : value?.fee,
            })
          ),
        })
      );
    }
  };

  const searchHandle = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const value = e.target.value;
    const result = searchItem(value);
    setItems(result);
    setSearch(value);
    setPage(0);
  };

  const handleXButton = () => {
    setItems(itemsDropDown);
    setSearch("");
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <TextField
        placeholder="Search item description."
        variant="outlined"
        value={search}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                size="small"
                onClick={handleXButton}
                onMouseDown={(e) => e.preventDefault()}
              >
                {search && <ClearIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        onChange={(e) => searchHandle(e)}
        margin="dense"
        autoFocus
      />
      {loading ? (
        <div className={classes.center_div}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className={local.items_items}>
            {items
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, index) => (
                <Grid key={index} container>
                  <Grid item xs={12} className={local.paging}>
                    <Card variant="outlined">
                      <CardActionArea
                        className={local.card}
                        onClick={() => handleClick(item)}
                        disabled={isSubmitting}
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={1}
                          wrap="nowrap"
                        >
                          <Grid item>
                            <Avatar
                              src={`${item.itemCategory.icon}`}
                              className={classes.large}
                            ></Avatar>
                          </Grid>
                          <Grid item xs>
                            <Typography variant="body2" component="p">
                              {item.itemDescription}
                            </Typography>
                            <Typography variant="caption" component="p">
                              {item.fullCode}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              className={classes.price}
                              align="right"
                              variant="body2"
                              component="p"
                            >
                              IDR {item.itemPrice.toLocaleString("en-US")}
                            </Typography>
                            {item.itemDisc > 0 && (
                              <Typography
                                variant="caption"
                                color="secondary"
                                component="p"
                              >
                                {`Disc: ${item.itemDisc}%`}
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </CardActionArea>
                    </Card>
                  </Grid>
                </Grid>
              ))}
          </div>
          <Paper className={local.paging} variant="outlined">
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={items ? items.length : 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Show"
            />
          </Paper>
        </>
      )}
    </>
  );
});
