import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useStyles } from "../../app/layout/style";
import { Item, ItemFormValues } from "../../app/models/item";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { v4 as uuid } from "uuid";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { Form, Formik } from "formik";
import Dropdown from "../../app/common/form/Dropdown";
import InputField from "../../app/common/form/InputField";
import MySwitch from "../../app/common/form/MySwitch";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import PriceInputField, {
  QtyInputField,
} from "../../app/common/form/NumberInputField";
import DateInputField from "../../app/common/form/DateInputFIeld";

export default observer(function ItemForm() {
  const classes = useStyles();
  const { itemStore, snackbarStore } = useStore();
  const {
    createItem,
    updateItem,
    loadItem,
    generateCode,
    itemCategories,
    loadCategories,
    loadItemTypes,
    loadingItem,
  } = itemStore;
  const { openSnackbar } = snackbarStore;
  const [item, setItem] = useState<ItemFormValues>(new ItemFormValues());
  const history = useHistory();
  let { id } = useParams<{ id: string }>();

  useEffect(() => {
    loadCategories();
    loadItemTypes();

    if (id) {
      loadItem(id).then((item) => {
        loadItemResult(item);
      });
    }
  }, [id, loadItem, loadItemTypes, loadCategories]);

  const schema = Yup.object({
    itemCategoryId: Yup.string().required("Item Category is required."),
    itemTypeId: Yup.string().required("Item Type is required."),
    itemDescription: Yup.string().required("Item description is required."),
    itemCode: Yup.string().required("Item Code is required."),
    price: Yup.number()
      .required("Price is required.")
      .typeError("Price is must be a number.")
      .positive("Price is must be greater than zero."),
    discount: Yup.number()
      .required("Discount is required.")
      .typeError("Discount is must be a number."),
  });

  const loadItemResult = (item: Item | undefined) => {
    console.log(item);
    if (item) {
      const value = new ItemFormValues({
        id: item.id,
        itemCategoryId: item.itemCategory.id,
        itemTypeId: item.itemType.id,
        itemDescription: item.itemDescription,
        itemCode: item.itemCode,
        isActive: item.isActive,
        price: item.itemPrice,
        discount: item.itemDisc,
        batch: item.batch,
        factoryName: item.factoryName,
        supplierName: item.supplierName,
        expiredDate: item.expiredDate,
        specialFee: item.specialFee,
      });
      setItem(value);
    }
  };

  const onCategoryChangeHandler = (
    setFieldValue: (
      field: string,
      value: string,
      shouldValidate?: boolean
    ) => void
  ) => {
    setFieldValue("itemCode", "", true);
    setFieldValue("itemTypeId", "", true);
  };

  const onTypeChangeHandler = (
    e: React.ChangeEvent<{
      value: unknown;
    }>,
    setFieldValue: (
      field: string,
      value: string,
      shouldValidate?: boolean
    ) => void
  ) => {
    var value = e.target.value as string;
    generateCode(value).then((result) => {
      setFieldValue("itemCode", result, true);
    });
  };

  const handleFormSubmit = (
    item: ItemFormValues,
    resetForm: () => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    if (!item.id) {
      const newId = uuid();
      let newItem = {
        ...item,
        id: newId,
      };
      createItem(newItem)
        .catch((error) => {
          openSnackbar(error, "error");
          setSubmitting(false);
        })
        .then((message) => {
          if (message !== undefined) {
            openSnackbar(message, "success");
            setItem(new ItemFormValues());
            resetForm();
          }
        });
    } else {
      updateItem(item)
        .catch((error) => {
          openSnackbar(error, "error");
          setSubmitting(false);
        })
        .then((message) => {
          if (message !== undefined) {
            openSnackbar(message, "success");
            history.push("/items");
          }
        });
    }
  };

  const selectItemTypes = (values: ItemFormValues) => {
    return itemCategories
      .find((x) => x.id === values.itemCategoryId)
      ?.types.find((x) => x.id === values.itemTypeId)?.description;
  };

  return (
    <>
      <Paper className={classes.form}>
        <Typography variant="h5">Form {id ? "Update" : "Create"}</Typography>
        <Divider />
        <Formik
          validationSchema={schema}
          enableReinitialize
          initialValues={item}
          onSubmit={(values, { resetForm, setSubmitting }) =>
            handleFormSubmit(values, resetForm, setSubmitting)
          }
        >
          {({
            handleSubmit,
            isSubmitting,
            isValid,
            values,
            setFieldValue,
            dirty,
          }) => (
            <Form onSubmit={handleSubmit} autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <Dropdown
                    options={itemCategories.map((x) => {
                      return { text: x.description, value: x.id };
                    })}
                    onChange={() => onCategoryChangeHandler(setFieldValue)}
                    name="itemCategoryId"
                    placeholder="Category"
                    label="Item Category"
                    disabled={isSubmitting || loadingItem}
                  />
                  <Dropdown
                    options={itemCategories
                      .find((x) => x.id === values.itemCategoryId)
                      ?.types.map((x) => {
                        return { text: x.description, value: x.id };
                      })}
                    onChange={(e) => onTypeChangeHandler(e, setFieldValue)}
                    name="itemTypeId"
                    placeholder="Type"
                    label="Item Type"
                    disabled={isSubmitting || loadingItem}
                  />
                  {selectItemTypes(values) ? (
                    <InputField
                      variant="standard"
                      label="Item Code"
                      name="itemCode"
                      placeholder="Please input item code"
                      start={`${selectItemTypes(values)} -`}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={isSubmitting || loadingItem}
                    />
                  ) : (
                    <InputField
                      variant="standard"
                      label="Item Code"
                      name="itemCode"
                      placeholder="Please input item code"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={isSubmitting || loadingItem}
                    />
                  )}
                  <InputField
                    variant="standard"
                    label="Item Description"
                    name="itemDescription"
                    placeholder="Please input item description"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={isSubmitting || loadingItem}
                  />
                  <Grid container direction="row" spacing={3}>
                    <Grid item>
                      <PriceInputField
                        variant="standard"
                        label="Price"
                        name="price"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        disabled={isSubmitting || loadingItem}
                      />
                    </Grid>
                    <Grid item>
                      <QtyInputField
                        variant="standard"
                        label="Discount"
                        name="discount"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        andorment="%"
                        margin="normal"
                        disabled={isSubmitting || loadingItem}
                      />
                    </Grid>
                    <Grid item>
                      {id && (
                        <MySwitch name="isActive" label="Active Item" checked />
                      )}
                    </Grid>
                    <Grid item>
                      <MySwitch name="specialFee" label="Special Fee" />
                    </Grid>
                  </Grid>
                </Grid>
                {values.itemCategoryId ===
                  "420e66ef-fa09-48e5-a0ed-08da689891cd" && (
                  <Grid item xs={12} lg={6}>
                    <Grid item>
                      <InputField
                        variant="standard"
                        label="Batch"
                        name="batch"
                        placeholder="Please input batch"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled={isSubmitting || loadingItem}
                      />
                      <InputField
                        variant="standard"
                        label="Factory Name"
                        name="factoryName"
                        placeholder="Please input factory name"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled={isSubmitting || loadingItem}
                      />
                      <InputField
                        variant="standard"
                        label="Supplier Name"
                        name="supplierName"
                        placeholder="Please input supplier name"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled={isSubmitting || loadingItem}
                      />
                      <DateInputField
                        disableToolbar={false}
                        label="Expired Date"
                        name="expiredDate"
                        placeholder="Please input expired date"
                        disabled={isSubmitting || loadingItem}
                        minDate={new Date()}
                        inputVariant="standard"
                        margin="normal"
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid
                container
                justifyContent="flex-end"
                alignItems="stretch"
                spacing={1}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting || !isValid || !dirty}
                  >
                    {isSubmitting && (
                      <CircularProgress
                        className={classes.progress}
                        size={16}
                        color="inherit"
                      />
                    )}
                    Save
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="default"
                    component={Link}
                    to="/items"
                    disabled={isSubmitting || loadingItem}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </>
  );
});
