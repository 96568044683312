import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import {
  MedicalPersonnel,
  MedicalPersonnelFormValues,
  MedicalPersonnelSearch,
} from "../models/medicalPersonnel";
import { PaginatedResult, Pagination } from "../models/pagination";
import { Order, SortBy } from "../models/table";

export default class MedicalPersonnelStore {
  personRegistry = new Map<string, MedicalPersonnel>();
  persons: MedicalPersonnel[] = [];
  personnelsFee: MedicalPersonnel[] = [];
  loadingPerson = false;
  predicate = new Map<string, string>();
  predicatePersonnelFee = new Map<string, string>();
  rowsOptions = [6];
  pagination: Pagination | null = null;
  sortBy: SortBy<MedicalPersonnel>[] = [
    { id: "name", label: "Person Name" },
    { id: "fee", label: "Fee" },
  ];
  orderBy: Order = "asc";

  constructor() {
    makeAutoObservable(this);
    this.predicatePersonnelFee.set(
      "startDate",
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      ).toLocaleDateString("EN-US")
    );
    this.predicatePersonnelFee.set(
      "endDate",
      new Date().toLocaleDateString("EN-US")
    );
    this.predicate.set("pageNumber", "1");
    this.predicate.set("orderBy", this.orderBy);

    reaction(
      () => this.predicatePersonnelFee.keys(),
      () => {
        this.loadPersonnelFee();
      }
    );

    reaction(
      () => this.predicate.keys(),
      () => {
        this.loadPersons();
      }
    );
  }

  setPredicate = (value: string) => {
    this.predicate.delete("search");
    this.predicate.set("search", value);
    this.predicate.delete("pageNumber");
    this.predicate.set("pageNumber", "1");
  };

  setPredicatePersonnelFee = (search: MedicalPersonnelSearch) => {
    Object.keys(search).forEach((key, value) => {
      this.predicatePersonnelFee.delete(key);
    });
    this.predicatePersonnelFee.set(
      "startDate",
      search.startDate.toLocaleDateString("EN-US")
    );
    this.predicatePersonnelFee.set(
      "endDate",
      search.endDate.toLocaleDateString("EN-US")
    );
  };

  setPageNumber = (value: number) => {
    this.predicate.delete("pageNumber");
    this.predicate.set("pageNumber", value.toString());
  };

  setPageSize = (value: number) => {
    this.predicate.delete("pageSize");
    this.predicate.set("pageSize", value.toString());
    // this.predicate.set("pageNumber", "1");
  };

  setSortBy = (value: Extract<keyof MedicalPersonnel, string> | null) => {
    this.predicate.delete("sortBy");
    if (value !== null) this.predicate.set("sortBy", value.toString());
  };

  setOrderBy = (value: Order) => {
    this.predicate.delete("orderBy");
    this.predicate.set("orderBy", value.toString());
    this.orderBy = value;
  };

  setRowsOptions = (value: number[]) => {
    this.rowsOptions = value;
  };

  get axiosParams() {
    const params = new URLSearchParams();
    this.predicate.forEach((value, key) => {
      params.delete(key);
      params.append(key, value);
    });
    return params;
  }

  get axiosParamsPersonnelFee() {
    const params = new URLSearchParams();
    this.predicatePersonnelFee.forEach((value, key) => {
      params.delete(key);
      params.append(key, value);
    });
    return params;
  }

  get pageNumber() {
    return Number(this.predicate.get("pageNumber")) - 1;
  }

  get pageSize() {
    return Number(this.predicate.get("pageSize"));
  }

  loadPersonProcess = (result: PaginatedResult<MedicalPersonnel[]>) => {
    runInAction(() => {
      this.personRegistry.clear();
      result.data.forEach((item) => {
        this.personRegistry.set(item.id, item);
      });
      this.pagination = result.pagination;
    });
  };

  loadPersons = async () => {
    this.loadingPerson = true;

    try {
      const result = await agent.MedicalPersonnels.list(this.axiosParams);
      this.loadPersonProcess(result);
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingPerson = false));
    }
  };

  get personList() {
    return Array.from(this.personRegistry);
  }

  loadPerson = async (id: string) => {
    this.loadingPerson = true;

    try {
      const result = await agent.MedicalPersonnels.details(id);
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingPerson = false));
    }
  };

  loadDropdownPerson = async () => {
    this.loadingPerson = true;

    try {
      const result = await agent.MedicalPersonnels.dropdown();
      runInAction(() => {
        this.persons = result;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingPerson = false));
    }
  };

  loadPersonnelFee = async () => {
    this.loadingPerson = true;

    try {
      const result = await agent.MedicalPersonnels.dropdown();
      for await (const x of result) {
        const params = this.axiosParamsPersonnelFee;
        params.append("personnelId", x.id);
        x.details = await agent.MedicalPersonnels.personnelFee(params);
      }
      runInAction(() => {
        this.personnelsFee = result;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingPerson = false));
    }
  };

  createPerson = async (person: MedicalPersonnelFormValues) => {
    try {
      await agent.MedicalPersonnels.create(person);
      const result = await agent.MedicalPersonnels.list(this.axiosParams);
      this.loadPersonProcess(result);
      return "Create person success!";
    } catch (error) {
      throw error;
    }
  };

  updatePerson = async (person: MedicalPersonnelFormValues) => {
    try {
      const result = await agent.MedicalPersonnels.update(person);
      runInAction(() => {
        this.personRegistry.set(result.id, result);
      });

      return "Update person success!";
    } catch (error) {
      throw error;
    }
  };

  deletePerson = async (id: string) => {
    this.loadingPerson = true;

    try {
      await agent.MedicalPersonnels.delete(id);
      const result = await agent.MedicalPersonnels.list(this.axiosParams);
      this.loadPersonProcess(result);
      return "Delete person success!";
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingPerson = false));
    }
  };
}
