import {
  Button,
  Popper,
  Grow,
  Paper,
  Typography,
  Divider,
  CircularProgress,
  Grid,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React from "react";
import { useStyles } from "../../app/layout/style";
import { useStore } from "../../app/stores/store";
import FilterListIcon from "@material-ui/icons/FilterList";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { InvoiceListSearch } from "../../app/models/sale";
import DateInputField from "../../app/common/form/DateInputFIeld";
import SearchFilter from "../../app/common/form/SearchFilter";
import Dropdown2 from "../../app/common/form/Dropdown2";

const useLocalStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiTextField-root": {
        width: "40ch",
      },
    },
  })
);

export default observer(function InvoiceFilter() {
  const classes = useStyles();
  const useLocalClasses = useLocalStyles();
  const { storeStore, salesStore } = useStore();
  const { storeDropDown, loadingStore } = storeStore;
  const { setPredicate, predicate } = salesStore;
  const [openFilter, setOpenFilter] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [search, setSearch] = React.useState<InvoiceListSearch>(
    new InvoiceListSearch()
  );

  const handleToggle = () => {
    setOpenFilter((prevOpenFilter) => !prevOpenFilter);
    setSearch(
      new InvoiceListSearch({
        id:
          predicate.get("id") === undefined ||
          predicate.get("id") === ""
            ? "All"
            : predicate.get("id")!,
        startDate:
          predicate.get("startDate") === undefined
            ? new Date()
            : new Date(predicate.get("startDate")!),
        endDate:
          predicate.get("endDate") === undefined
            ? new Date()
            : new Date(predicate.get("endDate")!),
        customerName:
          predicate.get("customerName") === undefined
            ? ""
            : predicate.get("customerName")!,
        documentNo:
          predicate.get("documentNo") === undefined
            ? ""
            : predicate.get("documentNo")!,
      })
    );
  };

  const schema = Yup.object({
    id: Yup.string().required("Store is required."),
    startDate: Yup.date().required("Start date is required."),
    endDate: Yup.date().required("End date is required."),
  });

  const handleSearchClick = (
    values: InvoiceListSearch,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    setSubmitting(false);
    setPredicate(values);
    handleToggle();
  };

  return (
    <div ref={anchorRef}>
      <Button color="primary" size="small" onClick={handleToggle}>
        Filter <FilterListIcon />
      </Button>
      <Popper
        open={openFilter}
        anchorEl={anchorRef.current}
        transition
        placement="bottom-end"
        style={{ zIndex: 3 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-end" ? "right top" : "right bottom",
            }}
          >
            <Paper className={classes.form}>
              <Typography variant="subtitle2">Filter</Typography>
              <Divider />
              <Formik
                validationSchema={schema}
                enableReinitialize
                initialValues={search}
                onSubmit={(values, { setSubmitting }) =>
                  handleSearchClick(values, setSubmitting)
                }
              >
                {({ handleSubmit, values, isSubmitting }) => (
                  <Form
                    onSubmit={handleSubmit}
                    className={classes.root}
                    autoComplete="off"
                  >
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      spacing={1}
                      className={useLocalClasses.root}
                    >
                      <Grid item>
                        <Dropdown2
                          name="id"
                          options={storeDropDown.map((x) => {
                            return { text: x.name, value: x.id };
                          })}
                          label="Store"
                          variant="outlined"
                          margin="dense"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          displayDefaultSelected
                          titleDefaultSelected={"All Selected"}
                          disabled={isSubmitting || loadingStore}
                        />
                      </Grid>
                      <Grid item>
                        <DateInputField
                          label="Start Date"
                          name="startDate"
                          maxDate={values.endDate}
                          disabled={isSubmitting || loadingStore}
                          inputVariant="outlined"
                          margin="dense"
                        />
                      </Grid>
                      <Grid item>
                        <DateInputField
                          label="End Date"
                          name="endDate"
                          minDate={values.startDate}
                          disabled={isSubmitting || loadingStore}
                          inputVariant="outlined"
                          margin="dense"
                        />
                      </Grid>
                      <Grid item>
                        <SearchFilter
                          name="customerName"
                          label="Customer Name"
                          variant="outlined"
                          margin="dense"
                        />
                      </Grid>
                      <Grid item>
                        <SearchFilter
                          name="documentNo"
                          label="Invoice No."
                          variant="outlined"
                          margin="dense"
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {isSubmitting && (
                            <CircularProgress
                              className={classes.progress}
                              size={16}
                              color="inherit"
                            />
                          )}
                          Search
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
});
