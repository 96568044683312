import {
  Paper,
  Table,
  TableBody,
  TableContainer,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React from "react";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../app/common/table/EnhancedTableHead";
import { useStyles } from "../../app/layout/style";
import { StockBalance } from "../../app/models/inventories";
import { HeadCell, Order } from "../../app/models/table";
import { useStore } from "../../app/stores/store";
import ReportStockBalanceTableRow from "./ReportStockBalanceTableRow";
import ReportStockBalanceTableHead from "./ReportStockBalanceTableHead";
import ReportStockBalanceTableSkeleton from "./ReportStockBalanceTableSkeleton";

export default observer(function ReportStockBalanceTable() {
  const classes = useStyles();
  const { inventoriesStore, tableStore } = useStore();
  const { stockBalance, loadingStockBalance } = inventoriesStore;
  const { stableSort, getComparator } = tableStore;
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof StockBalance>("itemCode");
  const headCells: HeadCell<StockBalance>[] = [
    {
      id: "icon",
      align: "center",
      disablePadding: false,
      label: "",
      disableSort: true,
      skeletonShape: "circle",
    },
    {
      id: "itemCategory",
      align: "left",
      disablePadding: false,
      label: "Category",
      disableSort: false,
      skeletonShape: "text",
    },
    {
      id: "itemType",
      align: "left",
      disablePadding: false,
      label: "Type",
      disableSort: false,
      skeletonShape: "text",
    },
    {
      id: "itemCode",
      align: "left",
      disablePadding: false,
      label: "Item Code",
      disableSort: false,
      skeletonShape: "text",
    },
    {
      id: "itemDescription",
      align: "left",
      disablePadding: false,
      label: "Description",
      disableSort: false,
      skeletonShape: "text",
    },
    {
      id: "batch",
      align: "left",
      disablePadding: false,
      label: "Batch",
      disableSort: false,
      skeletonShape: "text",
    },
    {
      id: "supplierName",
      align: "left",
      disablePadding: false,
      label: "Supplier Name",
      disableSort: false,
      skeletonShape: "text",
    },
    {
      id: "factoryName",
      align: "left",
      disablePadding: false,
      label: "Factory Name",
      disableSort: false,
      skeletonShape: "text",
    },
    {
      id: "expiredDate",
      align: "left",
      disablePadding: false,
      label: "Expired Date",
      disableSort: false,
      skeletonShape: "text",
    },
    {
      id: "qty",
      align: "right",
      disablePadding: false,
      label: "Qty",
      disableSort: true,
      skeletonShape: "text",
    },
  ];

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property as keyof StockBalance);
  };

  return (
    <Paper>
      <TableContainer>
        <Table
          className={classes.table}
          size="medium"
          component="table"
        >
          <ReportStockBalanceTableHead
            headCells={headCells}
            loading={loadingStockBalance}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {loadingStockBalance ? (
              <ReportStockBalanceTableSkeleton row={6} cellArray={headCells} />
            ) : stockBalance.length === 0 ? (
              <StyledTableRow>
                <StyledTableCell align="center" colSpan={headCells.length + 1}>
                  No Data.
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              stableSort(stockBalance, getComparator(order, orderBy)).map(
                (row, index) => (
                  <ReportStockBalanceTableRow row={row} key={index} />
                )
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
});
