import {
  Card,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  CardContent,
  Typography,
  Grid,
  CardActions,
  Collapse,
  Avatar,
  Divider,
  Button,
  Chip,
} from "@material-ui/core";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import { useStyles } from "../../app/layout/style";
import { StockControl } from "../../app/models/stockControl";
import { useStore } from "../../app/stores/store";

interface Props {
  stock: StockControl;
  deleteStock: (id: string) => void;
  handlePost: (
    event: React.MouseEvent<unknown>,
    id: string,
    posted: boolean
  ) => void;
}

export default observer(function StockContorlCard(props: Props) {
  const classes = useStyles();
  const { userStore } = useStore();
  const { user } = userStore;
  const { stock, deleteStock, handlePost } = props;
  const [expanded, setExpanded] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const totalQty = stock.details.reduce(
    (total, currentData) => (total = total + currentData.qty),
    0
  );
  const role = user?.roles.find((x) => x.name === "Developer");
  const restricted = role
    ? false
    : new Date(stock.date).setHours(0, 0, 0, 0) <
      new Date().setHours(0, 0, 0, 0);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDelete = (id: string) => {
    setAnchorEl(null);
    deleteStock(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card>
      <CardHeader
        action={
          <>
            <IconButton aria-label="settings" onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                disabled={stock.posted}
                component={Link}
                to={`/stockcontrol/${stock.id}`}
              >
                Update
              </MenuItem>
              <MenuItem
                disabled={stock.posted}
                onClick={() => handleDelete(stock.id)}
              >
                Delete
              </MenuItem>
            </Menu>
          </>
        }
        title={stock.documentNo}
        subheader={moment(stock.date).format("MMMM DD, YYYY")}
      />
      <CardContent>
        <SubHeaderCard
          stock={stock}
          deleteStock={deleteStock}
          handlePost={handlePost}
        />
      </CardContent>
      <CardActions disableSpacing>
        {!role ? (
          stock.posted && restricted ? (
            "Posted"
          ) : (
            <Button
              onClick={(e) => handlePost(e, stock.id, stock.posted)}
              variant="outlined"
              color={stock.posted ? "secondary" : "primary"}
            >
              {stock.posted ? "Unpost" : "Post"}
            </Button>
          )
        ) : (
          <Button
            onClick={(e) => handlePost(e, stock.id, stock.posted)}
            variant="outlined"
            color={stock.posted ? "secondary" : "primary"}
          >
            {stock.posted ? "Unpost" : "Post"}
          </Button>
        )}
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {stock.details.map((stock) => (
            <div key={stock.id}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Chip
                    variant="outlined"
                    color="primary"
                    label={stock.stockTransactionType.type}
                  />
                </Grid>
                <Grid item>
                  <Avatar src={`${stock.itemCategory.icon}`}></Avatar>
                </Grid>
                <Grid item xs>
                  <Typography variant="body2" component="p">
                    {stock.itemDescription}
                  </Typography>
                  <Typography variant="caption" component="p">
                    {stock.fullCode}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" component="span" align="right">
                    {stock.qty.toLocaleString("en-US")}
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
            </div>
          ))}
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <Typography variant="subtitle1">Total:</Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">
                {totalQty.toLocaleString("en-US")}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
});

function SubHeaderCard({ stock }: Props) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        marginTop: "8px",
      }}
    >
      <LocationCityIcon />
      <span style={{ marginLeft: "8px" }}>{stock.stockLocationName}</span>
    </div>
  );
}
