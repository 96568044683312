import { Paper, TableContainer, Table, TableBody } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React from "react";
import {
  StyledTableRow,
  StyledTableCell,
} from "../../app/common/table/EnhancedTableHead";
import { HeadCell } from "../../app/models/table";
import { useStore } from "../../app/stores/store";
import ReportStockBalanceTableSkeleton from "./ReportStockBalanceTableSkeleton";
import { SalesReport } from "../../app/models/sale";
import { useStyles } from "../../app/layout/style";
import ReportSalesTableHead from "./ReportSalesTableHead";
import ReportSalesTableRow from "./ReportSalesTableRow";

export default observer(function ReportSalesTable() {
  const classes = useStyles();
  const { salesReportStore } = useStore();
  const { salesReport, setSortBy, sortBy, orderBy, loading } = salesReportStore;
  const headCells: HeadCell<SalesReport>[] = [
    {
      id: "date",
      align: "left",
      disablePadding: false,
      label: "Date",
      disableSort: false,
      skeletonShape: "text",
    },
    {
      id: "documentNo",
      align: "left",
      disablePadding: false,
      label: "Invoice No.",
      disableSort: false,
      skeletonShape: "text",
    },
    {
      id: "itemCategory",
      align: "left",
      disablePadding: false,
      label: "Category",
      disableSort: false,
      skeletonShape: "text",
    },
    {
      id: "itemType",
      align: "left",
      disablePadding: false,
      label: "Type",
      disableSort: false,
      skeletonShape: "text",
    },
    {
      id: "itemCode",
      align: "left",
      disablePadding: false,
      label: "Item Code",
      disableSort: false,
      skeletonShape: "text",
    },
    {
      id: "itemDescription",
      align: "left",
      disablePadding: false,
      label: "Description",
      disableSort: false,
      skeletonShape: "text",
    },
    {
      id: "itemDisc",
      align: "right",
      disablePadding: false,
      label: "Disc",
      disableSort: true,
      skeletonShape: "text",
    },
    {
      id: "qty",
      align: "center",
      disablePadding: false,
      label: "Qty",
      disableSort: true,
      skeletonShape: "text",
    },
    {
      id: "totalAmount",
      align: "right",
      disablePadding: false,
      label: "Total Amount",
      disableSort: true,
      skeletonShape: "text",
    },
  ];

  const totalTotalAmount = salesReport.reduce(
    (total, currentData) => (total = total + currentData.totalAmount),
    0
  );

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = sortBy === property && orderBy === "asc";
    setSortBy(property as keyof SalesReport, isAsc ? "desc" : "asc");
  };

  return (
    <Paper>
      <TableContainer>
        <Table className={classes.table} size="medium" component="table">
          <ReportSalesTableHead
            headCells={headCells}
            loading={loading}
            order={orderBy}
            orderBy={sortBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {loading ? (
              <ReportStockBalanceTableSkeleton row={6} cellArray={headCells} />
            ) : salesReport.length === 0 ? (
              <StyledTableRow>
                <StyledTableCell align="center" colSpan={headCells.length + 1}>
                  No Data.
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              salesReport.map((row, index) => (
                <ReportSalesTableRow row={row} key={index} />
              ))
            )}
            {loading ? (
              <ReportStockBalanceTableSkeleton row={1} cellArray={headCells} />
            ) : (
              salesReport.length !== 0 && (
                <StyledTableRow>
                  <StyledTableCell colSpan={8} align="right">
                    Grand Total
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {totalTotalAmount.toLocaleString()}
                  </StyledTableCell>
                </StyledTableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
});
