import {
  CircularProgress,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import React from "react";
import PriceInputField from "../../app/common/form/NumberInputField";
import { useStyles } from "../../app/layout/style";
import { ItemDropdown } from "../../app/models/item";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { SalesMedicalPersonnelValues } from "../../app/models/sale";

interface Props {
  item: ItemDropdown;
  handleConfirm: (item: ItemDropdown, value?: SalesMedicalPersonnelValues) => void;
}

export default function POSGeneralItemDialog({ item, handleConfirm }: Props) {
  const classes = useStyles();
  const { dialogStore, medicalPersonnelStore } = useStore();
  const { persons, loadingPerson } = medicalPersonnelStore;
  const [value] = React.useState<SalesMedicalPersonnelValues>({
    medicalPersonnel: persons[0],
    price: item.itemPrice,
    disc: item.itemDisc,
    fee: 0,
  });
  const { closeDialog } = dialogStore;

  const schema = Yup.object({
    price: Yup.number()
      .typeError("Price is must be a number.")
      .positive("Price is must be greater then zero")
      .required("Price is required."),
    disc: Yup.number()
      .typeError("Discount is must be a number.")
      .required("Discount is required."),
  });

  const handleFormSubmit = (value: SalesMedicalPersonnelValues) => {
    let newItem: ItemDropdown = {
      ...item,
      itemPrice: value.price as number,
      itemDisc: value.disc as number,
    };
    handleConfirm(newItem, value);
    closeDialog();
  };

  return (
    <Formik
      validationSchema={schema}
      enableReinitialize
      initialValues={value}
      onSubmit={(value) => handleFormSubmit(value)}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit} autoComplete="off">
          <DialogTitle id="form-dialog-title">{`(${item.fullCode}) - ${item.itemDescription}`}</DialogTitle>
          <DialogContent>
            <Grid container direction="row" spacing={2}>
              <Grid item xs>
                <PriceInputField
                  variant="standard"
                  label="Price"
                  name="price"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  disabled={isSubmitting || loadingPerson}
                />
              </Grid>
              <Grid item xs>
                <PriceInputField
                  variant="standard"
                  label="Discount Value"
                  name="disc"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  disabled={isSubmitting || loadingPerson}
                />
              </Grid>
              {item.specialFee && (
                <Grid item xs>
                  <PriceInputField
                    variant="standard"
                    label="Special Fee"
                    name="fee"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="normal"
                    disabled={isSubmitting || loadingPerson}
                  />
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={closeDialog}
              color="primary"
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button type="submit" color="primary" disabled={isSubmitting}>
              {isSubmitting && (
                <CircularProgress
                  className={classes.progress}
                  size={16}
                  color="inherit"
                />
              )}
              Confirm
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
}
