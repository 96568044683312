import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  TablePagination,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useStore } from "../../app/stores/store";
import { Form, Formik } from "formik";
import POSCustomerCard from "./POSCustomerCard";
import SearchInput from "../../app/common/form/SearchInput";
import { usePosStyles } from "../../app/layout/pos";
import { useStyles } from "../../app/layout/style";
import { Skeleton } from "@material-ui/lab";
import { Customer } from "../../app/models/customer";

interface Props {
  handleSelectCustomer: (customer: Customer) => void;
}

export default observer(function POSCustomerDialog({
  handleSelectCustomer,
}: Props) {
  const classes = useStyles();
  const local = usePosStyles();
  const theme = useTheme();
  const widthSizeMatch = useMediaQuery(theme.breakpoints.up("xl"));
  const { dialogStore, customerStore } = useStore();
  const {
    setPredicate,
    loadingCustomer,
    customerList,
    pagination,
    rowsOptions,
    setRowsOptions,
    pageNumber,
    pageSize,
    setPageNumber,
    setPageSize,
    predicate,
  } = customerStore;
  const { closeDialog } = dialogStore;
  const [search, setSearch] = React.useState({
    search:
      predicate.get("search") === undefined ? "" : predicate.get("search"),
  });
  const skeletonCardArray = Array(widthSizeMatch ? 8 : 6).fill("");

  useEffect(() => {
    if (customerList.length === 0) {
      if (widthSizeMatch) {
        setRowsOptions([8, 8 * 2, 8 * 4, 8 * 8]);
        setPageSize(8);
      } else {
        setRowsOptions([6, 6 * 2, 6 * 4, 6 * 8]);
        setPageSize(6);
      }
    }
  }, [customerList.length, widthSizeMatch, setRowsOptions, setPageSize]);

  const handleFormSubmit = (
    value: any,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    setPredicate(value.search);
    setSearch(value.search);
    setSubmitting(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNumber(1);
  };

  return (
    <>
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>Search Customer</Grid>
          <Grid item>
            <Formik
              initialValues={search}
              onSubmit={(value, { setSubmitting }) =>
                handleFormSubmit(value, setSubmitting)
              }
            >
              {({ handleSubmit, isSubmitting }) => (
                <Form onSubmit={handleSubmit} autoComplete="off">
                  <SearchInput
                    placeholder="Search..."
                    name="search"
                    autoFocus
                  />
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container direction="row" spacing={1}>
          {loadingCustomer ? (
            skeletonCardArray.map((customer, index) => (
              <Grid key={index} item xs={12} md={6} lg={4} xl={3}>
                <Paper variant="outlined" className={classes.content}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>
                      <Skeleton
                        animation="wave"
                        variant="circle"
                        width={50}
                        height={50}
                      />
                    </Grid>
                    <Grid item xs>
                      <Typography variant="subtitle1">
                        <Skeleton animation="wave" variant="text" width="50%" />
                      </Typography>
                      <Typography variant="caption">
                        <Skeleton animation="wave" variant="text" width="80%" />
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button>
                        <Skeleton animation="wave" variant="rect" width="80%" />
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ))
          ) : customerList.length === 0 ? (
            <Grid item xs>
              No Data.
            </Grid>
          ) : (
            customerList.map(([id, customer]) => (
              <Grid key={id} item xs={12} md={6} lg={4} xl={3}>
                <POSCustomerCard
                  customer={customer}
                  handleSelectCustomer={handleSelectCustomer}
                  closeDialog={closeDialog}
                />
              </Grid>
            ))
          )}
        </Grid>
        <Paper variant="outlined" className={local.paging}>
          <TablePagination
            rowsPerPageOptions={rowsOptions}
            component="div"
            count={pagination === null ? 0 : pagination.totalItems}
            page={pageNumber}
            onPageChange={handleChangePage}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={closeDialog}
          color="primary"
          disabled={loadingCustomer}
        >
          Cancel
        </Button>
      </DialogActions>
    </>
  );
});
