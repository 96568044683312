interface Category {
  id: string;
  description: string;
}

export interface ItemType extends Category {}

export interface ItemCategory extends Category {
  icon: string;
  haveStock: boolean;
  types: ItemType[];
}

export interface ItemCategoryWithNoTypes extends Category {
  icon: string;
  haveStock: boolean;
}

export interface Image {
  id: string;
  url: string;
}

export interface ItemDocument {
  itemCategory: ItemCategoryWithNoTypes;
  itemType: ItemType;
  itemDescription: string;
  itemCode: string;
  itemPrice: number;
  itemDisc: number;
  specialFee: boolean;
}

export interface ItemDropdown extends ItemDocument {
  id: string;
  fullCode: string;
}

export interface Item {
  id: string;
  itemCategory: ItemCategory;
  itemType: ItemType;
  itemDescription: string;
  itemCode: string;
  itemPrice: number;
  itemDisc: number;
  fullCode: string;
  batch: string;
  factoryName: string;
  supplierName: string;
  expiredDate: Date;
  isActive: boolean;
  specialFee: boolean;
}

export class ItemFormValues {
  id?: string = undefined;
  itemCategoryId: string = "";
  itemTypeId: string = "";
  itemDescription: string = "";
  itemCode: string = "";
  price: number = Number.NaN;
  discount: number = Number.NaN;
  batch?: string = "";
  factoryName?: string = "";
  supplierName?: string = "";
  expiredDate?: Date = new Date();
  isActive: boolean = true;
  specialFee: boolean = true;

  constructor(item?: ItemFormValues) {
    if (item) {
      this.id = item.id;
      this.itemCategoryId = item.itemCategoryId;
      this.itemTypeId = item.itemTypeId;
      this.itemDescription = item.itemDescription;
      this.itemCode = item.itemCode;
      this.price = item.price;
      this.discount = item.discount;
      this.batch = item.batch;
      this.factoryName = item.factoryName;
      this.supplierName = item.supplierName;
      this.expiredDate = item.expiredDate;
      this.isActive = item.isActive;
      this.specialFee = item.specialFee;
    }
  }
}
