import { makeAutoObservable, reaction, runInAction } from "mobx";
import { SalesReport, SalesReportSearch } from "../models/sale";
import { Order } from "../models/table";
import agent from "../api/agent";

export default class SalesReportStore {
  salesReport: SalesReport[] = [];
  loading = false;
  predicate = new Map<string, string>();
  sortBy: Extract<keyof SalesReport, string> = "date";
  orderBy: Order = "asc";

  constructor() {
    makeAutoObservable(this);
    this.predicate.set("startDate", new Date().toLocaleDateString("EN-US"));
    this.predicate.set("endDate", new Date().toLocaleDateString("EN-US"));
    this.predicate.set("itemCategoryId", "");
    this.predicate.set("itemTypeId", "");
    this.predicate.set("itemDescription", "");
    this.predicate.set("sortBy", "");
    this.predicate.set("orderBy", this.orderBy);

    reaction(
      () => this.predicate.keys(),
      () => {
        this.loadSalesReport();
      }
    );
  }

  setPredicate = (search: SalesReportSearch) => {
    Object.keys(search).forEach((key) => {
      this.predicate.delete(key);
    });
    this.predicate.set(
      "startDate",
      search.startDate.toLocaleDateString("EN-US")
    );
    this.predicate.set("endDate", search.endDate.toLocaleDateString("EN-US"));
    this.predicate.set(
      "itemCategoryId",
      search.itemCategoryId === "All" ? "" : search.itemCategoryId
    );
    this.predicate.set(
      "itemTypeId",
      search.itemTypeId === "All" ? "" : search.itemTypeId
    );
    this.predicate.set("itemDescription", search.itemDescription);
  };

  setSortBy = (value: Extract<keyof SalesReport, string>, order: Order) => {
    this.predicate.delete("sortBy");
    this.predicate.set("sortBy", value.toString());
    this.sortBy = value;
    this.predicate.delete("orderBy");
    this.predicate.set("orderBy", order);
    this.orderBy = order;
  };

  setOrderBy = (value: Order) => {
    this.predicate.delete("orderBy");
    this.predicate.set("orderBy", value.toString());
    this.orderBy = value;
  };

  get axiosParams() {
    const params = new URLSearchParams();
    this.predicate.forEach((value, key) => {
      params.delete(key);
      params.append(key, value);
    });

    return params;
  }

  loadSalesReport = async () => {
    this.loading = true;

    try {
      const result = await agent.Sales.salesReport(this.axiosParams);
      runInAction(() => {
        this.salesReport = result;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };
}
