import {
  createTheme,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React from "react";
import { usePosStyles } from "../../app/layout/pos";
import { useStyles } from "../../app/layout/style";
import { Sale } from "../../app/models/sale";
import { useStore } from "../../app/stores/store";

type Props = {
  componentToPrint: React.MutableRefObject<HTMLDivElement | null>;
  sales?: Sale | undefined;
};

export default observer(function POSSlip(props: Props) {
  const classes = useStyles();
  const posStyles = usePosStyles();
  const { salesStore } = useStore();
  const { getTotalQty, getSubTotal } = salesStore;
  const { componentToPrint, sales } = props;
  const totalQty = getTotalQty(sales?.details);
  const subTotal = getSubTotal(sales?.details);
  const totalAmount = subTotal - (sales?.disc === undefined ? 0 : sales.disc);
  const totalPayment = sales?.payments.reduce(
    (total, currentData) => (total = total + currentData.amount),
    0
  );

  let theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          type: "light",
        },
        typography: {
          fontFamily: `"Open Sans", "Helvetica", "Arial", sans-serif`,
          fontWeightRegular: 400,
        },
        overrides: {
          MuiDivider: {
            root: {
              margin: "8px 0px 8px 0px",
            },
          },
        },
      }),
    []
  );

  return (
    <ThemeProvider theme={theme}>
      <div
        ref={(el) => (componentToPrint.current = el)}
        className={posStyles.slip}
      >
        <Grid container direction="row" alignItems="center" spacing={1}>
          <Grid item>
            <img
              src="/persadamedic.jpeg"
              alt="logo"
              className={classes.logo_slip}
            />
          </Grid>
          <Grid item>
            <Typography variant="h5">PERSADA MEDIC</Typography>
            <p>
              {sales?.store.city} <br />
              {sales?.store.phoneNo}
            </p>
          </Grid>
        </Grid>
        <Divider />
        <table>
          <tbody>
            <tr>
              <td>Date</td>
              <td>:</td>
              <td>{moment(sales?.date).format("DD-MMM-yyyy")}</td>
            </tr>
            <tr>
              <td>Invoice</td>
              <td>:</td>
              <td>{sales?.documentNo}</td>
            </tr>
            <tr>
              <td>Store</td>
              <td>:</td>
              <td>{sales?.store.name}</td>
            </tr>
            <tr>
              <td>Customer</td>
              <td>:</td>
              <td>{sales?.customer.customerName.toLocaleUpperCase()}</td>
            </tr>
          </tbody>
        </table>
        <Typography variant="h6">Transaction</Typography>
        <TableContainer
          component={Paper}
          style={{ margin: theme.spacing(1, 0) }}
        >
          <Table className={posStyles.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">Type</TableCell>
                <TableCell align="center">Personnel</TableCell>
                <TableCell align="center">Description</TableCell>
                <TableCell align="center">Disc</TableCell>
                <TableCell align="right">Price</TableCell>
                <TableCell align="center">Qty</TableCell>
                <TableCell align="right">Total Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sales?.details.map((detail, index) => (
                <TableRow key={index}>
                  <TableCell align="center">
                    {detail.itemType.description}
                  </TableCell>
                  <TableCell align="center">{detail.personnel?.name}</TableCell>
                  <TableCell align="center">{detail.itemDescription}</TableCell>
                  <TableCell align="center">
                    {detail.disc.toLocaleString()}
                  </TableCell>
                  <TableCell align="right">
                    {detail.price.toLocaleString()}
                  </TableCell>
                  <TableCell align="center">
                    {detail.qty.toLocaleString()}
                  </TableCell>
                  <TableCell align="right">
                    {detail.totalAmount.toLocaleString()}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={5} align="right">
                  Disc Total:
                </TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="right">
                  {sales?.disc.toLocaleString()}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={5} align="right">
                  Grand Total:
                </TableCell>
                <TableCell align="center">
                  {totalQty.toLocaleString()}
                </TableCell>
                <TableCell align="right">
                  {totalAmount.toLocaleString()}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Typography variant="h6">Payments</Typography>
        <TableContainer
          component={Paper}
          style={{ margin: theme.spacing(1, 0), width: "50%" }}
        >
          <Table className={posStyles.table} size="small">
            <TableBody>
              {sales?.payments.map((payment, index) => (
                <TableRow key={index}>
                  <TableCell>{payment.paymentType.type}</TableCell>
                  <TableCell align="right">
                    {payment.amount.toLocaleString()}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell>Grand Total:</TableCell>
                <TableCell align="right">
                  {totalPayment?.toLocaleString()}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          spacing={1}
        >
          <Grid item xs></Grid>
          <Grid item>
            <Typography variant="subtitle2" align="center">
              Finance Manager
            </Typography>
            <div className={classes.center_div}>
              <img src="/ttd.png" alt="ttd" className={classes.ttd} />
            </div>
            <Typography variant="subtitle2" align="center">
              Komang Try Damayanti
            </Typography>
          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  );
});
