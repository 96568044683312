import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import {
  FilterType,
  SalesPaymentChart,
  SalesRevenueChart,
  SalesRevenueData,
  SalesTrendChart,
  StockBalanceChart,
} from "../models/dashboard";

export default class DashboardStore {
  filter: string = "date";
  filterFormat: string = "MMM-d-yyyy";
  filterType: FilterType[] = [];
  filterValue = new Date();
  date = new Date();
  revenue: SalesRevenueData | undefined = undefined;
  revenueChart: SalesRevenueChart[] = [];
  paymentChart: SalesPaymentChart[] = [];
  stockBalanceChart: StockBalanceChart[] = [];
  salesTrendChart: SalesTrendChart[] = [];
  loadingRevenue = false;
  loadingRevenueChart = false;
  loadingPaymentChart = false;
  loadingStockBalanceChart = false;
  loadingSalesTrendChart = false;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.date,
      () => {
        this.loadRevenue();
        this.loadRevenueChart();
        this.loadPaymentChart();
        this.loadStockBalanceChart();
      }
    );
  }

  setFilter = (value: string) => {
    switch (value) {
      case "year":
        this.filterType = ["year"];
        this.filterFormat = "yyyy";
        break;
      case "month":
        this.filterType = ["year", "month"];
        this.filterFormat = "MMM-yyyy";
        break;
      case "date":
        this.filterType = ["date"];
        this.filterFormat = "MMM-d-yyyy";
        break;
    }

    this.filter = value;
  };

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("date", this.date.toLocaleDateString("EN-US"));

    return params;
  }

  setFilterValue = (value: Date) => {
    this.filterValue = value;
  };

  setDate = (value: Date) => {
    this.date = value;
  };

  loadRevenue = async () => {
    this.loadingRevenue = true;

    try {
      const result = await agent.Sales.salesRevenue(this.axiosParams);
      runInAction(() => {
        this.revenue = result;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingRevenue = false));
    }
  };

  loadRevenueChart = async () => {
    this.loadingRevenueChart = true;

    try {
      const result = await agent.Sales.salesRevenueChart(this.axiosParams);
      runInAction(() => {
        this.revenueChart = result;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingRevenueChart = false));
    }
  };

  loadPaymentChart = async () => {
    this.loadingPaymentChart = true;

    try {
      const result = await agent.Sales.salesPaymentChart(this.axiosParams);
      runInAction(() => {
        this.paymentChart = result;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingPaymentChart = false));
    }
  };

  loadStockBalanceChart = async () => {
    this.loadingStockBalanceChart = true;

    try {
      const result = await agent.Inventories.stockBalanceChart(
        this.axiosParams
      );
      runInAction(() => {
        this.stockBalanceChart = result;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingStockBalanceChart = false));
    }
  };
}
