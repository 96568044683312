import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import DateInputField from "../../app/common/form/DateInputFIeld";
import Dropdown from "../../app/common/form/Dropdown";
import { useStyles } from "../../app/layout/style";
import { CashFlowListSearch } from "../../app/models/cashFlow";
import { useStore } from "../../app/stores/store";
import CashFlowTable from "./CashFlowTable";

export default observer(function CashFlowList() {
  const classes = useStyles();
  const { cashFlowStore, salesStore } = useStore();
  const { loadBankAccount, bankAccounts, loadingSales } = salesStore;
  const { loadDocuments, predicate, setPredicate, open, setOpen } =
    cashFlowStore;
  const today = React.useMemo(() => new Date(), []);
  const firstDayOfMonth = React.useMemo(
    () => new Date(today.getFullYear(), today.getMonth(), 1),
    [today]
  );
  const [search, setSearch] = React.useState<CashFlowListSearch>(
    new CashFlowListSearch({
      startDate:
        predicate.get("startDate") === undefined
          ? firstDayOfMonth
          : new Date(predicate.get("startDate")!),
      endDate:
        predicate.get("endDate") === undefined
          ? today
          : new Date(predicate.get("endDate")!),
      bankAccountId:
        predicate.get("id") === undefined
          ? ""
          : predicate.get("id")!,
    })
  );

  useEffect(() => {
    loadBankAccount().then((result) => {
      const search = {
        startDate:
          predicate.get("startDate") === undefined
            ? firstDayOfMonth
            : new Date(predicate.get("startDate")!),
        endDate:
          predicate.get("endDate") === undefined
            ? today
            : new Date(predicate.get("endDate")!),
        bankAccountId:
          predicate.get("id") === undefined
            ? result!
            : predicate.get("id")!,
      };
      setSearch(new CashFlowListSearch(search));
    });
  }, [loadBankAccount, firstDayOfMonth, today, predicate, setPredicate]);

  const handleSearchClick = (
    values: CashFlowListSearch,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    loadDocuments(values.startDate, values.endDate, values.bankAccountId).then(
      () => {
        setPredicate(values);
        setSubmitting(false);
        setOpen(true);
      }
    );
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={search}
        onSubmit={(values, { setSubmitting }) =>
          handleSearchClick(values, setSubmitting)
        }
      >
        {({ handleSubmit, values, isSubmitting }) => (
          <Form onSubmit={handleSubmit} autoComplete="off">
            <FormControl fullWidth>
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <DateInputField
                    label="Start Date"
                    name="startDate"
                    maxDate={values.endDate}
                    placeholder="Please input start date"
                    disabled={isSubmitting || loadingSales}
                    inputVariant={"standard"}
                    margin="normal"
                  />
                </Grid>
                <Grid item>
                  <DateInputField
                    label="End Date"
                    name="endDate"
                    minDate={values.startDate}
                    placeholder="Please input end date"
                    disabled={isSubmitting || loadingSales}
                    inputVariant={"standard"}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs>
                  <Dropdown
                    options={bankAccounts.map((x) => {
                      return {
                        text: `${x.owner} - ${x.bankName} - ${x.accountNo}`,
                        value: x.id,
                      };
                    })}
                    name="bankAccountId"
                    placeholder="Bank Account"
                    label="Bank Account"
                    disabled={isSubmitting || loadingSales}
                    displayDefaultSelected
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting || loadingSales}
                  >
                    {isSubmitting && (
                      <CircularProgress
                        className={classes.progress}
                        size={16}
                        color="inherit"
                      />
                    )}
                    Search
                  </Button>
                </Grid>
              </Grid>
            </FormControl>
          </Form>
        )}
      </Formik>
      <Grid container>
          <Grid item xs={12}>
            <CashFlowTable open={open} />
          </Grid>
        </Grid>
    </>
  );
});
